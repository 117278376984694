
import { defineComponent, PropType } from 'vue'

interface Styles {
  [key: string]: string
}

export default defineComponent({
  props: {
    num: {
      type: Number,
      required: true,
    },
    fill: {
      type: String,
      default: '#333',
    },
    styles: {
      type: Object as PropType<Styles>,
    },
  },
})
