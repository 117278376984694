<template>
  <div class="playerBg" :class="{ '-show': mode === 'full' }"></div>
  <div
    class="player"
    :class="{
      '-full': mode === 'full',
      '-mini': mode === 'mini',
    }"
    :style="{ transform: `translateY(${touchState.move}px)` }"
  >
    <div v-show="mode === 'full'">
      <div
        class="playerHeader"
        @touchstart="touchHeaderStart($event)"
        @touchmove="touchHeaderMove($event)"
        @touchend="touchHeaderEnd()"
      >
        <button
          type="button"
          class="playerHeader_btn"
          @click="
            () => {
              guideMeta.closeDescription()
              sizeChange()
            }
          "
        >
          <img src="/img/common/arrow_down.svg" :alt="alt[lang].down" />
        </button>
      </div>

      <div class="playerCont">
        <div class="playerCont_number">
          <SvgNumber :num="guideNumber + 1" />
        </div>

        <AudioMeta
          ref="guideMeta"
          :lang="lang"
          :audioName="guideName || ''"
          :audioDescription="guideDescription || ''"
          :gtagTrackOpenText="gtagTrackOpenText"
        />

        <AudioController
          :lang="lang"
          :totalTime="totalTime"
          :currentTime="currentTime"
          :setCurrentTime="setCurrentTime"
          :isPlaying="isPlaying"
          :disableMovePrev="disableMovePrev"
          :disableMoveNext="disableMoveNext"
          :play="play"
          :pause="pause"
          :moveGuide="
            (direction) => {
              guideMeta.closeDescription()
              moveGuide(direction)
            }
          "
        />
      </div>
    </div>

    <div class="playerMiniControl" v-show="mode === 'mini'">
      <button type="button" class="playerMiniControlInfo" @click="sizeChange">
        <span class="playerMiniControlInfo_number">
          <SvgNumber
            :num="guideNumber + 1"
            fill="#fff"
            :styles="{ width: 'auto', height: '12px', verticalAlign: 'top' }"
          />
        </span>
        <span class="playerMiniControlInfo_name">{{ guideName }}</span>
      </button>

      <div class="playerMiniControlBtn">
        <button type="button" @click="() => play()" v-show="!isPlaying">
          <img src="img/common/play.svg" :alt="alt[lang].play" />
        </button>
        <button type="button" @click="pause" v-show="isPlaying">
          <img src="img/common/pause.svg" :alt="alt[lang].stop" />
        </button>
        <button
          type="button"
          class="playerMiniControlBtn_close"
          @click="() => close()"
        >
          <img src="/img/common/close.svg" :alt="alt[lang].close" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, ref, reactive } from 'vue'
import AudioController from '@/components/block/AudioController.vue'
import AudioMeta from '@/components/block/AudioMeta.vue'
import SvgNumber from '@/components/elements/SvgNumber.vue'

export default defineComponent({
  name: 'GuidePlayer',

  components: {
    AudioController,
    AudioMeta,
    SvgNumber,
  },

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    mode: {
      type: String,
      required: true,
    },
    guideNumber: {
      type: Number,
      required: true,
    },
    guideName: {
      type: String,
      required: true,
    },
    guideDescription: {
      type: String,
      required: true,
    },
    totalTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    disableMovePrev: {
      type: Boolean,
      default: true,
    },
    disableMoveNext: {
      type: Boolean,
      default: true,
    },
    play: {
      type: Function as PropType<() => void>,
      required: true,
    },
    pause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    moveGuide: {
      type: Function as PropType<(direction: string) => void>,
      required: true,
    },
    setCurrentTime: {
      type: Function as PropType<(time: number) => void>,
      required: true,
    },
    sizeChange: {
      type: Function as PropType<() => void>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  setup(props) {
    const guideMeta = ref()

    const touchState = reactive({
      start: 0,
      move: 0,
    })

    const touchHeaderStart = (ev: any) => {
      touchState.start = Math.floor(ev.touches[0].pageY)
    }

    const touchHeaderMove = (ev: any) => {
      const end = Math.floor(ev.touches[0].pageY)

      if (touchState.start > end) return

      const move = end - touchState.start

      if (move < 100) touchState.move = move
    }

    const touchHeaderEnd = () => {
      if (touchState.move > 50) {
        guideMeta.value.closeDescription()
        props.sizeChange()
      }
      touchState.move = 0
    }

    const gtagTrackOpenText = (): void => {
      gtag('event', `guide_text_${props.guideNumber + 1}`)
    }

    const alt = {
      ja: {
        down: '小さくする',
        play: '再生',
        stop: '停止',
        close: '閉じる',
      },
      en: {
        down: 'Make it smaller',
        play: 'play',
        stop: 'stop',
        close: 'close',
      },
      zh: {
        down: '让它更小',
        play: '再生产',
        stop: '停止',
        close: '特写',
      },
      ko: {
        down: '작게',
        play: '재생',
        stop: '정지',
        close: '닫기',
      },
    }

    return {
      guideMeta,
      touchState,
      touchHeaderStart,
      touchHeaderMove,
      touchHeaderEnd,
      gtagTrackOpenText,
      alt,
    }
  },
})
</script>

<style scoped lang="scss">
.playerBg {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.6);
  display: none;

  &.-show {
    display: block;
  }
}

.player {
  position: fixed;
  z-index: 10;
  bottom: -100vh;
  left: 0;
  width: 100vw;
  height: 485px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  // padding: 35px;
  // visibility: visible;
  z-index: 100;

  &.-full {
    bottom: 0;

    .-os-android & {
      bottom: 40px;
    }
  }

  &.-mini {
    height: 70px;
    bottom: 0;

    .-os-android & {
      bottom: 40px;
    }
  }
}

.playerHeader {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  position: relative;
  z-index: 1;

  &_btn {
    padding: 10px;
    margin-left: auto;

    img {
      display: block;
    }
  }
}

.playerCont {
  height: 485px;
  padding: 50px 35px 30px;
  margin-top: -50px;
  overflow-y: auto;
  position: relative;
  z-index: 0;

  &_number {
    width: 120px;
    height: 120px;
    border: 8px solid rgb(54, 175, 223, 0.65);
    margin-bottom: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    svg {
      width: auto;
      height: 35px;
    }
  }

  .-mini & {
    padding: 20px;
  }
}

.playerMiniControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px;
}

.playerMiniControlInfo {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  overflow: hidden;

  &_number {
    display: block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    flex-basis: 30px;
    background-color: var.$color-primary;
    border-radius: 50%;
    margin-right: 10px;
    padding-top: 9px;
  }

  &_name {
    height: 30px;
    font-size: 1.8rem;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.playerMiniControlBtn {
  height: 30px;
  display: flex;
  align-items: center;
  flex-basis: 70px;

  button {
    display: block;
    height: 30px;
    width: 30px;
  }

  img {
    width: auto;
    height: 100%;
  }

  &_close {
    padding: 4px;
    margin-left: 10px;
    width: auto;
  }
}
</style>
