
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SvgTextQuizTitle',
  props: {
    lang: {
      type: String,
      default: 'ja',
    },
  },
})
