<template>
  <div ref="root">
    <div
      class="thanksSection_wrap"
      :class="{
        'is-show': isShow,
        'is-wideBottom': bottomMargin,
      }"
    >
      <div class="thanksSection" :class="{ 'is-show': isShow }">
        <div class="thanksSection_inner">
          <p class="thanksSection_title">
            <img :src="`img/thanks/${lang}/title.svg`" :alt="alt[lang]" />
          </p>
          <div class="thanksSection_img">
            <img src="img/thanks/main.png" alt="" />
          </div>
          <div class="thanksSection_fujiko">
            <img
              src="img/thanks/fujiko.svg"
              alt="川崎市藤子・F・不二雄ミュージアム"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, watch, ref, nextTick } from 'vue'
// eslint-disable-next-line
// @ts-ignore 
import Confetti from 'vue-confetti/src/confetti.js'
export default defineComponent({
  name: 'thanksSection',

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    bottomMargin: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: 'ja',
    },
  },

  setup(props) {
    const state = reactive({
      confetti: new Confetti(),
      thanksToTop: null,
    })
    const root = ref<HTMLDivElement>()

    watch(
      () => props.isShow,
      () => {
        startConfettiHandler()
      },
      { deep: true }
    )

    const startConfettiHandler = () => {
      state.confetti.start({
        particles: [
          {
            type: 'rect',
            size: 5,
          },
          {
            type: 'rect',
            size: 5,
          },
          {
            type: 'rect',
            size: 4,
          },
          {
            type: 'rect',
            size: 6,
          },
        ],
        defaultColors: ['#ffb101', '#5DBEE0', '#FF838D', '#299738'],
        particlesPerFrame: 0.1,
        windSpeedMax: 1.5,
        defaultDropRate: 10,
      })
      nextTick(() => {
        const rootTop = root.value?.offsetTop
        window.scroll({
          top: rootTop,
          behavior: 'smooth',
        })
      })

      setTimeout(() => {
        state.confetti.stop()
      }, 15000)
    }

    const alt = {
      ja: '全部聞いてくれてありがとう！引き続き館内をお楽しみください！',
      en: 'Wow! You listened to the whole audio guide! Thank you very much! ',
      zh: '谢谢您收听了全部解说！欢迎继续参观！',
      ko: '모두 들어 줘서 고마워요! 뮤지엄을 계속 즐겨 주세요!',
    }

    return {
      state,
      root,
      alt,
    }
  },
})
</script>
<style scoped lang="scss">
.thanksSection_wrap {
  display: none;
  transition: 0.5s;
  overflow: hidden;

  &.is-show {
    display: block;
  }

  &.is-wideBottom {
    padding-bottom: 70px;
  }
}
.thanksSection_canvas {
  display: block;
  position: fixed;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 11;
}
.thanksSection {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 30px 35px 75px;
  text-align: center;
  visibility: hidden;
  &.is-show {
    visibility: visible;
    display: flex;
  }
}
.thanksSection_title {
  margin-bottom: 15px;
}
.thanksSection_img {
  margin-bottom: 15px;
  img {
    width: 174px;
  }
}
</style>
<style lang="scss">
#confetti-canvas {
  z-index: 11;
}
</style>
