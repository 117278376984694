
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, ref, reactive } from 'vue'
import AudioController from '@/components/block/AudioController.vue'
import AudioMeta from '@/components/block/AudioMeta.vue'
import SvgNumber from '@/components/elements/SvgNumber.vue'

export default defineComponent({
  name: 'GuidePlayer',

  components: {
    AudioController,
    AudioMeta,
    SvgNumber,
  },

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    mode: {
      type: String,
      required: true,
    },
    guideNumber: {
      type: Number,
      required: true,
    },
    guideName: {
      type: String,
      required: true,
    },
    guideDescription: {
      type: String,
      required: true,
    },
    totalTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    disableMovePrev: {
      type: Boolean,
      default: true,
    },
    disableMoveNext: {
      type: Boolean,
      default: true,
    },
    play: {
      type: Function as PropType<() => void>,
      required: true,
    },
    pause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    moveGuide: {
      type: Function as PropType<(direction: string) => void>,
      required: true,
    },
    setCurrentTime: {
      type: Function as PropType<(time: number) => void>,
      required: true,
    },
    sizeChange: {
      type: Function as PropType<() => void>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  setup(props) {
    const guideMeta = ref()

    const touchState = reactive({
      start: 0,
      move: 0,
    })

    const touchHeaderStart = (ev: any) => {
      touchState.start = Math.floor(ev.touches[0].pageY)
    }

    const touchHeaderMove = (ev: any) => {
      const end = Math.floor(ev.touches[0].pageY)

      if (touchState.start > end) return

      const move = end - touchState.start

      if (move < 100) touchState.move = move
    }

    const touchHeaderEnd = () => {
      if (touchState.move > 50) {
        guideMeta.value.closeDescription()
        props.sizeChange()
      }
      touchState.move = 0
    }

    const gtagTrackOpenText = (): void => {
      gtag('event', `guide_text_${props.guideNumber + 1}`)
    }

    const alt = {
      ja: {
        down: '小さくする',
        play: '再生',
        stop: '停止',
        close: '閉じる',
      },
      en: {
        down: 'Make it smaller',
        play: 'play',
        stop: 'stop',
        close: 'close',
      },
      zh: {
        down: '让它更小',
        play: '再生产',
        stop: '停止',
        close: '特写',
      },
      ko: {
        down: '작게',
        play: '재생',
        stop: '정지',
        close: '닫기',
      },
    }

    return {
      guideMeta,
      touchState,
      touchHeaderStart,
      touchHeaderMove,
      touchHeaderEnd,
      gtagTrackOpenText,
      alt,
    }
  },
})
