
import {
  defineComponent,
  PropType,
  computed,
  reactive,
  ref,
  onMounted,
} from 'vue'

export default defineComponent({
  name: 'AudioController',

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    totalTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    setCurrentTime: {
      type: Function as PropType<(time: number) => void>,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    disableMovePrev: {
      type: Boolean,
      default: true,
    },
    disableMoveNext: {
      type: Boolean,
      default: true,
    },
    play: {
      type: Function as PropType<() => void>,
      required: true,
    },
    pause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    moveGuide: {
      type: Function as PropType<(direction: string) => void>,
    },
  },

  setup(props) {
    // 時間の表示を変換(ex: 90 -> 1:30)
    const convertTime = (time: number): string => {
      return Math.floor(time / 60) + ':' + ('0' + (time % 60)).slice(-2)
    }

    // プログレスバーの長さを取得
    const progressCurrentTime = ref(0)

    // プログレスバーの長さを取得
    const progressWidth = ref(window.innerWidth - 70)

    const controlState = reactive({
      isTouchProgress: false, // シークバーの操作中かどうか
      isTouchMoveBtn: false, // ガイドの移動ボタンを押したかどうか
      isProgressNoAnimation: false, //操作終了後に1秒だけアニメーションしないようにするフラグ
    })

    const touchState = reactive({
      start: 0,
      move: 0,
      currentTime: 0,
      movedTime: 0,
    })

    // シークバー操作開始 //
    // eslint-disable-next-line
    const touchBarStart = (ev: any) => {
      controlState.isTouchProgress = true
      touchState.start = Math.floor(ev.touches[0].pageX)
      touchState.currentTime = props.currentTime
    }

    // シークバー操作中
    // eslint-disable-next-line
    const touchBarMove = (ev: any) => {
      const end = Math.floor(ev.touches[0].pageX)

      touchState.move = end - touchState.start

      touchState.movedTime = Math.round(
        (touchState.move / progressWidth.value) * props.totalTime
      )
    }

    // シークバー操作終了
    const touchBarEnd = () => {
      const result = props.currentTime + touchState.movedTime

      if (result <= 0) {
        props.setCurrentTime(0)
        progressCurrentTime.value = 0
      } else if (result >= props.totalTime) {
        props.setCurrentTime(props.totalTime)
        progressCurrentTime.value = props.totalTime
      } else {
        props.setCurrentTime(result)
        progressCurrentTime.value = result
      }

      controlState.isTouchProgress = false
      touchState.move = 0

      controlState.isProgressNoAnimation = true
      setTimeout(() => (controlState.isProgressNoAnimation = false), 1000)
    }

    const clickMoveBtn = (direction: string): void => {
      if (!props.moveGuide) return

      props.moveGuide(direction)
      controlState.isTouchMoveBtn = true
      setTimeout(() => (controlState.isTouchMoveBtn = false), 1000)
    }

    // 現在時間を表示
    const currentTimeHTML = computed(() => {
      // タッチ操作中でなければpropsをそのまま表示
      if (!controlState.isTouchProgress) return convertTime(props.currentTime)

      // タッチ操作中は移動距離を含めて計算して返す
      const result = props.currentTime + touchState.movedTime

      if (result <= 0) return convertTime(0)
      if (result >= props.totalTime) return convertTime(props.totalTime)

      return convertTime(props.currentTime + touchState.movedTime)
    })

    // 残り時間を表示
    const remainingTimeHTML = computed(() => {
      // タッチ操作中でなければpropsからそのまま表示
      if (!controlState.isTouchProgress)
        return '-' + convertTime(props.totalTime - props.currentTime)

      // タッチ操作中は移動距離を含めて計算して返す
      const result = props.currentTime + touchState.movedTime

      if (result <= 0) return '-' + convertTime(props.totalTime)
      if (result >= props.totalTime) return '-' + convertTime(0)

      return '-' + convertTime(props.totalTime - result)
    })

    // プログレスの長さの計算
    const calculateProgressPosition = computed(() => {
      if (controlState.isTouchMoveBtn)
        return {
          width: '0',
        }

      // タッチ終わりはタッチで移動した
      if (controlState.isProgressNoAnimation)
        return {
          width:
            Math.round((progressCurrentTime.value / props.totalTime) * 100) +
            '%',
        }

      let width = ''

      if (controlState.isTouchProgress) {
        const movedRange = Math.round(
          (Math.abs(touchState.move) / progressWidth.value) * 100
        )
        if (touchState.move > 0) {
          const result = Math.round(
            (touchState.currentTime / props.totalTime) * 100 + movedRange
          )
          width = result > 100 ? '100%' : result + '%'
        } else {
          const result = Math.round(
            (touchState.currentTime / props.totalTime) * 100 - movedRange
          )
          width = result <= 0 ? '0' : result + '%'
        }
      } else {
        width = Math.round((props.currentTime / props.totalTime) * 100) + '%'
      }

      return {
        width: width,
      }
    })

    const alt = {
      ja: {
        play: '再生',
        stop: '停止',
        next: '次へ',
        prev: '前へ',
      },
      en: {
        play: 'play',
        stop: 'stop',
        next: 'next',
        prev: 'prev',
      },
      zh: {
        play: '再生产',
        stop: '停止',
        next: '到下一个',
        prev: '向前',
      },
      ko: {
        play: '재생',
        stop: '정지',
        next: '다음에',
        prev: '이전',
      },
    }

    return {
      currentTimeHTML,
      remainingTimeHTML,
      calculateProgressPosition,
      touchBarStart,
      touchBarMove,
      touchBarEnd,
      clickMoveBtn,
      progressWidth,
      controlState,
      alt,
    }
  },
})
