<template>
  <div class="main">
    <div class="mainGreeting">
      <h1 class="mainTitle">
        <img
          :src="`img/main/${state.lang}/title.svg`"
          :alt="alt.title[state.lang]"
        />
      </h1>
      <button
        class="mainUsage"
        :isListen="isAudioListened(0, 'listened_guide')"
        @click="tapUsageBtn()"
      >
        {{ usageData.name[state.lang] }}
      </button>
      <div class="mainVisusal">
        <img src="img/main/mv.png" :alt="alt.mv[state.lang]" />
      </div>
      <AudioButton
        color="primary"
        :text="alt.greeting[state.lang]"
        :isListen="isAudioListened(0, 'listened_guide')"
        :num="1"
        :handleClick="() => tapGuideBtn(0)"
        :lang="state.lang"
      />
    </div>

    <div
      class="mainGuide"
      :class="{
        '-hasMiniPlayer':
          guidePlayerState.mode === 'mini' && !state.isShowthanksSection,
      }"
    >
      <section
        class="mainSection"
        v-for="(section, key, sectionIndex) in $store.getters.getGuideContents"
        :key="key"
      >
        <h2 class="mainSectionTitle">
          <span class="mainSectionTitle_inner">
            <img :src="`/img/main/floor_${key}.svg`" alt="" />
            <img
              :src="`/img/main/${state.lang}/title_${key}.svg`"
              :alt="section.label"
            />
          </span>
        </h2>
        <ul class="guideList">
          <li v-for="guide in section.guide" :key="guide.index">
            <AudioButton
              :color="btnColor(sectionIndex)"
              :isListen="isAudioListened(guide.index, 'listened_guide')"
              :num="guide.index + 1"
              :handleClick="() => tapGuideBtn(guide.index)"
            />
          </li>
          <li v-for="quiz in section.quiz" :key="quiz.index">
            <AudioButton
              :color="btnColor(sectionIndex)"
              :isListen="isAudioListened(quiz.index, 'listened_quiz')"
              quiz
              :num="quiz.index"
              :handleClick="() => playQuiz(quiz.index)"
              :lang="state.lang"
            />
          </li>
        </ul>

        <div class="mainSectionImg" v-if="key !== 'fujiko_sunday'">
          <img :src="`/img/main/img_${key}.png`" alt="" />
        </div>
      </section>
    </div>

    <ThanksSection
      :lang="state.lang"
      :isShow="state.isShowthanksSection"
      :bottomMargin="guidePlayerState.mode === 'mini'"
    />
    <footer class="mainCopyright">
      <small>&copy; Fujiko-Pro</small>
    </footer>

    <!-- 音声ガイド -->
    <GuidePlayer
      :lang="state.lang"
      :mode="guidePlayerState.mode"
      :guideNumber="guidePlayerState.currentAudio"
      :guideName="guidePlayerState.name"
      :guideDescription="guidePlayerState.description"
      :totalTime="audioState.totalTime"
      :currentTime="audioState.currentTime"
      :setCurrentTime="setCurrentTime"
      :isPlaying="guidePlayerState.isPlaying"
      :disableMovePrev="guidePlayerState.currentAudio === 0"
      :disableMoveNext="
        $store.state.contents &&
        guidePlayerState.currentAudio === $store.state.contents.guide.length
      "
      :play="
        () => {
          audioElement.play()
          guidePlayerState.isPlaying = true
        }
      "
      :pause="
        () => {
          audioElement.pause()
          guidePlayerState.isPlaying = false
        }
      "
      :moveGuide="moveGuide"
      :sizeChange="
        () => {
          if (guidePlayerState.mode === 'full') {
            guidePlayerState.mode = 'mini'
            $store.commit('unFixBody')
          } else {
            guidePlayerState.mode = 'full'
            $store.commit('fixBody')
          }
        }
      "
      :close="
        () => {
          audioElement.pause()
          guidePlayerState.mode = 'hide'
          setCurrentTime(0)
          checkAllAudioComplete()
        }
      "
    />

    <!-- つかいかた -->
    <SimplePlayer
      playerID="usage"
      :lang="state.lang"
      :mode="usageState.mode"
      :guideName="usageData.name[state.lang]"
      :guideDescription="usageData.description[state.lang]"
      :totalTime="audioState.totalTime"
      :currentTime="audioState.currentTime"
      :setCurrentTime="setCurrentTime"
      :isPlaying="usageState.isPlaying"
      :play="
        () => {
          audioElement.play()
          usageState.isPlaying = true
        }
      "
      :pause="
        () => {
          audioElement.pause()
          usageState.isPlaying = false
        }
      "
      :moveGuide="moveGuide"
      :close="
        () => {
          audioElement.pause()
          usageState.mode = 'hide'
          setCurrentTime(0)
          $store.commit('unFixBody')
        }
      "
    />

    <!-- クイズ -->
    <Quiz
      :lang="state.lang"
      :isShow="quizState.isShow"
      :quizQuestion="quizState.question"
      :quizDescription="quizState.description"
      :quizAnswer="quizState.answer"
      :quizComment="quizState.comment"
      :currentQuiz="quizState.currentQuiz"
      :totalTime="audioState.totalTime"
      :currentTime="audioState.currentTime"
      :setCurrentTime="setCurrentTime"
      :isPlaying="quizState.isPlaying"
      :play="
        () => {
          audioElement.play()
          quizState.isPlaying = true
        }
      "
      :pause="
        () => {
          audioElement.pause()
          quizState.isPlaying = false
        }
      "
      :close="() => closeQuiz(true)"
      :answerdQuizCorrectly="answerdQuizCorrectly"
      :returnQuizView="returnQuizView"
    />

    <RequestModal
      :lang="state.lang"
      :isShow="state.isShowRequestModal"
      @close="closeRequestModal"
      class="request"
    />
    <Auth
      :lang="state.lang"
      :isShow="isShowAuth"
      @success="successAuthView"
      class="auth"
    />

    <template v-if="!$store.state.isInitialized">
      <Splash :lang="state.lang" :completeLoadContents="completeLoadContents" />
    </template>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
declare let window: any // eslint-disable-line
import { defineComponent, PropType, reactive, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'
import Auth from '@/components/block/Auth.vue'
import RequestModal from '@/components/block/RequestModal.vue'
import ThanksSection from '@/components/block/ThanksSection.vue'
import GuidePlayer from '@/components/block/GuidePlayer.vue'
import SimplePlayer from '@/components/block/SimplePlayer.vue'
import Quiz from '@/components/block/Quiz.vue'
import AudioButton from '@/components/elements/AudioButton.vue'
import Splash from '@/components/block/Splash.vue'

interface GuidePlayerState {
  mode: 'hide' | 'mini' | 'full'
  name: string
  description: string
  isPlaying: boolean
  currentAudio: number
}

interface UsageState {
  mode: 'hide' | 'full'
  isPlaying: boolean
}

interface ListenedAudioState {
  listened_guide: number[]
  listened_quiz: number[]
  audioComplete: boolean
  alreadyAudioCompleted: boolean
}

export default defineComponent({
  name: 'Main',

  components: {
    Auth,
    RequestModal,
    ThanksSection,
    GuidePlayer,
    SimplePlayer,
    AudioButton,
    Quiz,
    Splash,
  },

  props: {
    isShowAuth: {
      type: Boolean,
      required: true,
    },
    reauthKey: {
      type: Function as PropType<() => void>,
      required: true,
    },
    successAuthorize: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  setup(props) {
    if (!props.isShowAuth) gtag('event', 'main')
    const store = useStore()
    const route = useRoute()
    const lang = route.params.lang
    store.commit('setLang', lang)
    Cookies.set(app_cookie.lang, lang, {
      expires: 1,
    })

    const state = reactive({
      isShowRequestModal: false,
      isShowthanksSection: false,
      lang: lang as 'ja' | 'en' | 'zh' | 'ko',
    })
    const guidePlayerState = reactive<GuidePlayerState>({
      mode: 'hide',
      name: '',
      description: '',
      isPlaying: false,
      currentAudio: 0,
    })
    const usageState = reactive<UsageState>({
      mode: 'hide',
      isPlaying: false,
    })
    const quizState = reactive({
      isShow: false,
      question: '',
      description: '',
      isPlaying: false,
      answer: {
        correct: '',
        option_a: '',
        option_b: '',
      },
      comment: {
        audio: '',
        description: '',
      },
      currentQuiz: 0,
    })
    const audioState = reactive({
      totalTime: 0,
      currentTime: 0,
    })
    const listenedAudioState = reactive<ListenedAudioState>({
      listened_guide: [],
      listened_quiz: [],
      audioComplete: false,
      alreadyAudioCompleted: false,
    })

    // カギのCookieを持っている場合は再認証を実行
    if (Cookies.get(app_cookie.todays_key)) {
      props.reauthKey()
    }

    // 認証成功時に認証画面を隠す
    const showRequestModal = () => (state.isShowRequestModal = true)

    // 視聴済みのガイド/クイズをリセット
    const resetListendItem = () => {
      Cookies.remove(app_cookie.listened_guide)
      Cookies.remove(app_cookie.listened_quiz)
      listenedAudioState.listened_guide.splice(0)
      listenedAudioState.listened_quiz.splice(0)
    }

    // 認証成功時
    const successAuthView = () => {
      props.successAuthorize()
      showRequestModal()
      resetListendItem()
      gtag('event', 'notice')
    }

    const audioElement = new Audio()

    onUnmounted(() => {
      audioElement.src = ''
      audioElement.pause()
      store.commit('unFixBody')
    })

    // オーディオガイドがセットされてメタデータが取得できたら総時間を更新
    audioElement.addEventListener('loadedmetadata', () => {
      audioState.totalTime = Math.round(audioElement.duration)
    })

    // 再生時間のアップデート
    audioElement.addEventListener('timeupdate', () => {
      audioState.currentTime = Math.round(audioElement.currentTime)
    })

    // 再生終了したら最初に戻る
    audioElement.addEventListener('ended', () => {
      audioState.currentTime = 0
      guidePlayerState.isPlaying = false
      usageState.isPlaying = false
      quizState.isPlaying = false
    })

    // ガイドの再生処理
    const playGuide = () => {
      const index = guidePlayerState.currentAudio
      audioElement.src = store.getters.getAudioGuide[index].audio
      guidePlayerState.name =
        index === 0
          ? alt.greeting[state.lang]
          : store.getters.getAudioGuide[index].name
      guidePlayerState.description =
        store.getters.getAudioGuide[index].description
      guidePlayerState.isPlaying = true
      quizState.isPlaying = false
      audioElement.play()
      gtag('event', `guide_${index + 1}`)
      setAudioCookie(guidePlayerState.currentAudio, 'listened_guide')
    }

    // つかいかたの再生処理、再生するとガイドとクイズは停止する
    const playUsage = () => {
      audioElement.src = usageData.audio[state.lang]
      usageState.isPlaying = true
      guidePlayerState.isPlaying = false
      guidePlayerState.mode = 'hide'
      quizState.isPlaying = false
      audioElement.play()
      gtag('event', 'usage')
    }

    // ガイドの再生(直接番号指定)
    const tapGuideBtn = (index: number) => {
      if (guidePlayerState.mode === 'hide') {
        store.commit('fixBody')
        guidePlayerState.mode = 'full'
      }
      guidePlayerState.currentAudio = index

      playGuide()
    }

    // つかいかたモーダル表示
    const tapUsageBtn = () => {
      store.commit('fixBody')
      usageState.mode = 'full'

      playUsage()
    }

    // ガイドの移動
    const moveGuide = (direction: string) => {
      if (direction === '+') guidePlayerState.currentAudio++
      if (direction === '-') guidePlayerState.currentAudio--

      playGuide()
    }

    const closeGuidePlayer = (): void => {
      if (guidePlayerState.mode === 'full') {
        store.commit('unFixBody')
        guidePlayerState.mode = 'mini'
        return
      }

      if (guidePlayerState.mode === 'mini') {
        guidePlayerState.mode = 'hide'
        audioElement.pause()
        return
      }
    }

    const setCurrentTime = (time: number): void => {
      audioElement.currentTime = time
    }

    const playQuiz = (index: number): void => {
      if (!store.state.contents) return

      setCurrentTime(0)
      const i = index - 1

      quizState.question = store.state.contents.quiz[i].question
      quizState.description = store.state.contents.quiz[i].description
      quizState.answer = store.state.contents.quiz[i].answer
      quizState.comment = store.state.contents.quiz[i].comment
      quizState.currentQuiz = index
      audioElement.src = store.state.contents.quiz[i].audio
      guidePlayerState.isPlaying = false
      guidePlayerState.mode = 'hide'
      quizState.isShow = true
      store.commit('fixBody')
      gtag('event', `quiz_${index}`)
      setAudioCookie(index, 'listened_quiz')

      const url = window.location.pathname + '?status=quiz'
      window.history.pushState({}, '', url)

      setTimeout(() => {
        quizState.isPlaying = true
        audioElement.play()
      }, 500)
    }

    const closeQuiz = (isTapBtn: boolean): void => {
      store.commit('unFixBody')
      audioElement.pause()
      setCurrentTime(0)
      quizState.isShow = false

      // ガイドすべて再生完了 && ガイドを縮小表示状態のときに、
      // クイズを再生後に閉じて、ガイド及びクイズが非表示になった場合にも
      // サンクス画面表示させるため
      checkAllAudioComplete()

      // ボタンから操作された場合はヒストリー操作をする
      if (isTapBtn) window.history.back()
    }

    window.onpopstate = () => {
      if (quizState.isShow === true) closeQuiz(false)
    }

    // ページ表示時にパラメータが残っていたらhistoryを削除
    if (quizState.isShow === false) {
      const querysArray = window.location.search.replace('?', '').split('&')
      const querysObj: Record<string, string> = {}
      querysArray.forEach((query: string) => {
        const q = query.split('=')
        querysObj[q[0]] = q[1]
      })
      if (querysObj.status === 'quiz') {
        window.history.back()
      }
    }

    const answerdQuizCorrectly = (): void => {
      const i = quizState.currentQuiz - 1
      audioElement.src = store.state.contents.quiz[i].comment.audio

      setTimeout(() => {
        quizState.isPlaying = true
        audioElement.play()
      }, 500)
    }

    const returnQuizView = (): void => {
      const i = quizState.currentQuiz - 1
      quizState.isPlaying = false
      audioElement.src = store.state.contents.quiz[i].audio
    }

    const btnColor = (index: number) => {
      switch (index % 3) {
        case 0:
          return 'primary'
        case 1:
          return 'secondary'
        case 2:
          return 'yellow'
      }
    }

    // cookieからガイド/クイズの再生済み取得
    const loadListenedAudio = () => {
      const loadedGuide = Cookies.get(app_cookie.listened_guide)
      const loadedQuiz = Cookies.get(app_cookie.listened_quiz)
      const loadedGuideArray = (loadedGuide && JSON.parse(loadedGuide)) || []
      const loadedQuizArray = (loadedQuiz && JSON.parse(loadedQuiz)) || []
      listenedAudioState.listened_guide = [...loadedGuideArray]
      listenedAudioState.listened_quiz = [...loadedQuizArray]
    }
    loadListenedAudio()
    // すべてのガイドの数(ガイド + はじめのごあいさつ)
    const allGuideLength = computed(() => {
      return store.state.contents?.guide.length + 1
    })
    // すべてのクイズ
    const allQuizLength = computed(() => store.state.contents?.quiz.length)
    // すべての音源を再生したか
    const isAllAudioComplete = () => {
      return (
        listenedAudioState.listened_guide.length === allGuideLength.value
        // && listenedAudioState.listened_quiz.length === allQuizLength.value
      )
    }

    // 音源を再生済みか
    const isAudioListened = (
      index: number,
      type: 'listened_guide' | 'listened_quiz'
    ) => {
      return listenedAudioState[type].includes(index)
    }

    // ガイド/クイズの再生したらstateとcookieに値保存
    const setAudioCookie = (
      index: number,
      type: 'listened_guide' | 'listened_quiz'
    ) => {
      if (!listenedAudioState[type].includes(index)) {
        listenedAudioState[type].push(index)
        Cookies.set(
          app_cookie[type],
          JSON.stringify(listenedAudioState[type]),
          {
            expires: 1 / 2,
          }
        )
      }
    }
    // サンクスモーダル表示
    const showthanksSection = () => {
      state.isShowthanksSection =
        !listenedAudioState.alreadyAudioCompleted &&
        listenedAudioState.audioComplete
      if (state.isShowthanksSection) gtag('event', 'main_complete')
    }
    // すべての音源を再生したか確認, すべて再生したらサンクス表示
    const checkAllAudioComplete = () => {
      listenedAudioState.audioComplete = isAllAudioComplete()
      showthanksSection()
    }

    // コンテンツ取得時にすでにすべての音源を再生しているか確認
    const completeLoadContents = (): void => {
      listenedAudioState.alreadyAudioCompleted = isAllAudioComplete()
    }

    // お願いモーダル閉じる
    const closeRequestModal = () => {
      state.isShowRequestModal = false
      gtag('event', 'main')
    }

    const alt = {
      title: {
        ja: 'ようこそミュージアムへ！ おはなしデンワで展示を楽しもう！',
        en:
          'welcome to the Museum! Enjoy the exhibits with me, the Gabby Guide!',
        zh: '欢迎来到博物馆！ 请使用解说电话收听展品介绍吧！',
        ko: '뮤지엄에 오신 것을 환영합니다! 이야기 전화로 전시를 즐겨 보세요!',
      },

      mv: {
        ja: 'ドラえもん のび太 インク',
        en: '',
        zh: '',
        ko: '',
      },

      greeting: {
        ja: 'ごあいさつ',
        en: 'Greeting',
        zh: '致辞',
        ko: '인사말',
      },
    }

    const usageData = {
      name: {
        ja: 'つかいかた',
        en: 'Tutorial',
        zh: '使用说明',
        ko: '사용법',
      },
      description: {
        ja:
          '「おはなしデンワの使い方」をご説明します。<br>展示室内の作品のそばに、番号が表示されています。<br>画面の中の同じ番号をタッチすると、作品の解説がスタートします。<br>本体を耳にあてて、聴いてください。<br>音で聴くのではなく、文字で読みたいときは、番号の下の「テキストを本文で見る」をタッチしてください。<br>他のお客様のご迷惑にならないよう音量を調節してください。<br>またミュージアムクイズは、お好きな場所でお楽しみください。<br>ご自身のスマートフォンでご利用中に画面が消えてしまったときにはQRコードを読み取ってください。',
        en:
          'Allow us to explain how to use the museum’s audio guide. <br>Near many of the exhibits on display, you will find a number on a sign. Tap that number on your audio guide device, commentary about the work will begin. Tap that number on your audio guide device and the commentary for that exhibit will begin. If you would rather read the text than listen to the audio, tap on the text below the number in the display. <br>Hold the guide up to your ear to listen. Please remember to keep the volume at a level that won’t bother the other visitors today. <br>You can also take the museum quiz wherever you like! If the display turns off, please scan the QR code again.',
        zh:
          '以下是“解说电话的使用说明”。<br>展厅中所展示的作品旁标有编号。<br>点击画面中的相同编号，就会启动作品解说。<br>请将“解说电话”放到耳边收听。<br>如果您不想收听语音解说，而是想浏览文字说明，则请点击编号下方的“查看文字解说”字样。<br>播放语音解说时，请注意调整音量，以免干扰其他参观者。<br>此外，您还可以自由选择场所，参加博物馆趣味竞答。<br>若您使用自己的智能手机时解说画面消失，请重新扫描二维码即可。',
        ko:
          '‘이야기 전화 사용법’을 설명해 드리겠습니다.<br>전시실 내의 작품 옆에 번호가 표시되어 있습니다.<br>화면에서 같은 번호를 터치하면 작품 해설이 시작됩니다.<br>본체를 귀에 대고 들어 주시기 바랍니다.<br>소리로 듣는 것보다 글자로 읽고 싶으실 때는, 번호 밑의 ‘텍스트로 본문 보기’를 탭하세요.<br>다른 분들이 불편하지 않으시도록 음량을 조절해 주시기 바랍니다.<br>또한 뮤지엄 퀴즈는 원하시는 곳에서 즐기실 수 있습니다.<br>가지고 계신 스마트폰으로 이용 중에 화면이 꺼진 경우에는 QR 코드를 스캔해 주세요.',
      },
      audio: {
        ja: '/audio/ja/usage.mp3',
        en: '/audio/en/usage.mp3',
        zh: '/audio/zh/usage.mp3',
        ko: '/audio/ko/usage.mp3',
      },
    }

    return {
      state,
      guidePlayerState,
      usageState,
      quizState,
      audioState,
      successAuthView,
      audioElement,
      tapGuideBtn,
      tapUsageBtn,
      playGuide,
      moveGuide,
      setCurrentTime,
      closeGuidePlayer,
      playQuiz,
      closeQuiz,
      answerdQuizCorrectly,
      returnQuizView,
      btnColor,
      listenedAudioState,
      allGuideLength,
      allQuizLength,
      isAudioListened,
      checkAllAudioComplete,
      completeLoadContents,
      closeRequestModal,
      alt,
      usageData,
    }
  },
})
</script>

<style lang="scss">
.mainGreeting {
  position: relative;
  z-index: 1;
  padding: 70px 35px 35px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top -15px left -20px;
  background-image: url('/img/main/logo_f.svg');
  mask-image: url('/img/common/wave_section.png');
  mask-repeat: repeat-x;
  mask-size: auto 100%;
}
.mainUsage {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 32px;
  width: 80px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  background: url('/img/usage/icon-button.png') no-repeat 50% 8px #36afdf;
  border-radius: 0 0 0 10px;
  background-size: 30px 30px;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  line-height: 1;
  color: #fff;
  font-weight: bold;
}
.mainTitle {
  text-align: center;
  margin-bottom: 12px;
}
.mainVisusal {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 10px;
  img {
    width: 201px;
  }
}
.mainGuide {
  position: relative;
  z-index: 0;
}

.mainSection {
  position: relative;
  margin-top: -35px;
  padding: 70px 35px 35px;
  mask-image: url('/img/common/wave_section.png');
  mask-repeat: repeat-x;
  mask-size: auto 100%;

  &:nth-child(7n + 1) {
    background-color: var.$color-primary-bg;
    background-image: url('/img/common/dot_blue.svg');
    z-index: 6;
    .mainSectionImg {
      margin-top: 21px;
      img {
        width: 217px;
      }
    }
  }

  &:nth-child(7n + 2) {
    background-color: var.$color-secondary-bg;
    background-image: url('/img/common/stripe_pink.svg');
    z-index: 5;
    .mainSectionImg {
      margin-top: 21px;
      img {
        width: 261px;
      }
    }
  }

  &:nth-child(7n + 3) {
    background-color: var.$color-yellow-bg;
    background-image: url('/img/common/dot_yellow.svg');
    z-index: 4;
    .mainSectionImg {
      margin-top: 23px;
      img {
        width: 181px;
      }
    }
  }

  &:nth-child(7n + 4) {
    background-color: var.$color-primary-bg;
    background-image: url('/img/common/stripe_blue.svg');
    z-index: 3;
    .mainSectionImg {
      img {
        width: 258px;
      }
    }
  }
  &:nth-child(7n + 5) {
    background-color: var.$color-secondary-bg;
    background-image: url('/img/common/dot_pink.svg');
    z-index: 2;
    .mainSectionImg {
      margin-top: 18px;
      img {
        width: 271px;
      }
    }
  }
  &:nth-child(7n + 6) {
    background-color: var.$color-yellow-bg;
    background-image: url('/img/common/stripe_yellow.svg');
    z-index: 1;
    .mainSectionImg {
      margin-top: 35px;
      img {
        width: 160px;
      }
    }
  }
  &:nth-child(7n + 7) {
    background-color: var.$color-primary-bg;
    background-image: url('/img/common/dot_blue.svg');
    mask-image: none;
    z-index: 0;
  }

  &:last-child {
    .-os-android & {
      padding-bottom: 75px;
    }
  }
}
.mainGuide.-hasMiniPlayer .mainSection:last-child {
  padding-bottom: 105px;

  .-os-android & {
    padding-bottom: 145px;
  }
}

.mainSectionTitle {
  height: 98px;
  width: 100%;
  margin-left: 15px;
  padding-bottom: 22px;
  margin-bottom: 32px;
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto;
  background-image: url('/img/main/dot_title.svg');
  &_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  img {
    &:nth-child(1) {
      position: absolute;
      left: -15px;
      top: 0;
      z-index: 0;
    }
    &:nth-child(2) {
      z-index: 1;
    }
  }
}
.mainSectionImg {
  text-align: center;
}

.mainCopyright {
  background-color: #fff;
  text-align: center;
  padding: 12px 12px 0;
  font-weight: 700;
  transform: translateY(-12px);
}

.guideList {
  display: flex;
  flex-wrap: wrap;
  max-width: 305px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;

  > li {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    &:first-child:last-child {
      margin-left: auto;
      margin-right: auto;
    }

    &:nth-child(n + 3) {
      margin-top: 20px;
    }
  }

  button {
    border: 1px solid #ccc;
    padding: 10px;
  }
}

.request {
  position: relative;
  z-index: 20;
}

.auth {
  position: relative;
  z-index: 21;
}
</style>
