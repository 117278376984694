
import { defineComponent, PropType } from 'vue'
import SvgNumber from '@/components/elements/SvgNumber.vue'
import SvgQuizText from '@/components/elements/SvgQuizText.vue'

export default defineComponent({
  name: 'AudioButton',

  components: {
    SvgNumber,
    SvgQuizText,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    num: {
      type: Number,
      required: true,
    },
    color: {
      validator: (value: string) => {
        return ['primary', 'secondary', 'yellow'].includes(value)
      },
      default: 'primary',
    },
    isListen: {
      type: Boolean,
      default: false,
    },
    quiz: {
      type: Boolean,
      default: false,
    },
    handleClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    lang: {
      validator: (value: string) => {
        return ['ja', 'en', 'zh', 'ko'].includes(value)
      },
      default: 'ja',
    },
  },

  setup(props) {
    return {
      colorClass: `audioBtn-color-${props.color}`,
    }
  },
})
