<template>
  <div class="audioMeta">
    <div class="audioName" :class="{ isSimple: usageNotice }" v-if="showName">
      <p>{{ audioName }}</p>
    </div>
    <p
      v-if="usageNotice"
      class="audioMetaNotice"
      v-html="contents[lang].usage"
    ></p>
    <div class="audioDescription">
      <div
        class="audioDescription_body"
        :style="{
          height: descriptionState.height + 'px',
          transitionDuration: descriptionState.duration,
        }"
      >
        <p
          class="audioDescription_text"
          ref="description"
          v-html="audioDescription"
        />
      </div>
      <button
        type="button"
        class="audioDescription_trg"
        :class="{ '-open': descriptionState.isOpen }"
        @click="toggleDescriptiion"
      >
        {{
          descriptionState.isOpen ? contents[lang].close : contents[lang].text
        }}
        <img src="/img/common/arrow_down.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, ref } from 'vue'

export default defineComponent({
  name: 'AudioMeta',

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    audioName: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    audioDescription: {
      type: String,
      required: true,
    },
    gtagTrackOpenText: {
      type: Function as PropType<() => void>,
    },
    usageNotice: {
      type: Boolean,
    },
  },

  setup(props) {
    const description = ref()
    const descriptionState = reactive({
      isOpen: false,
      height: 0,
      duration: '0.5s',
    })

    const toggleDescriptiion = () => {
      descriptionState.isOpen = descriptionState.isOpen ? false : true

      if (descriptionState.isOpen) {
        descriptionState.height = description.value.clientHeight
        if (props.gtagTrackOpenText) props.gtagTrackOpenText()
      } else {
        descriptionState.height = 0
      }
    }

    const closeDescription = () => {
      descriptionState.isOpen = false
      descriptionState.height = 0
      descriptionState.duration = '0s'
      setTimeout(() => (descriptionState.duration = '0.5s'), 500)
    }
    const contents = {
      ja: {
        close: 'とじる',
        text: 'テキストで本文を見る',
        usage:
          '<ruby>文字<rp>(</rp><rt>もじ</rt><rp>)</rp></ruby>で<ruby>読<rp>(</rp><rt>よ</rt><rp>)</rp></ruby>みたい<ruby>方<rp>(</rp><rt>かた</rt><rp>)</rp></ruby>はこちら',
      },
      en: {
        close: 'Close',
        text: 'Read the commentary in text format',
        usage: 'Please click here for the text version',
      },
      zh: {
        close: '关闭',
        text: '浏览正文文本',
        usage: '点此浏览文字说明',
      },
      ko: {
        close: '닫기',
        text: '텍스트로 본문 보기',
        usage: '글자로 읽고 싶은 분',
      },
    }

    return {
      description,
      descriptionState,
      toggleDescriptiion,
      closeDescription,
      contents,
    }
  },
})
</script>

<style scoped lang="scss">
.audioName {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 64px;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.5;

  &.isSimple {
    min-height: auto;
  }

  p {
    width: 100%;
  }
}

.audioMetaNotice {
  margin-bottom: 15px;
  text-align: center;
  color: #36afdf;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;

  &::before,
  &::after {
    content: '';
    width: 2px;
    height: 16px;
    background: #36afdf;
    display: inline-block;
    margin: 0 10px;
  }
  &::before {
    transform: translateY(2px) rotate(-32deg);
    transform-origin: left bottom;
  }
  &::after {
    transform: translateY(2px) rotate(32deg);
    transform-origin: right bottom;
  }
}

.audioDescription {
  margin-bottom: 45px;

  &_trg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    border: 1px solid #d3d3d3;
    border-radius: 18px;
    text-align: center;
    color: #333;

    img {
      display: block;
      margin-left: 10px;
      width: 14px;
      transition: transform 0.5s;
    }

    &.-open img {
      transform: rotateX(180deg);
    }
  }

  &_body {
    overflow: hidden;
    transition-property: height;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &_text {
    border-top: 1px solid #d3d3d3;
    padding: 20px 0;
  }
}
</style>
