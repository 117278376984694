
import { defineComponent, PropType, reactive, ref } from 'vue'

export default defineComponent({
  name: 'AudioMeta',

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    audioName: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    audioDescription: {
      type: String,
      required: true,
    },
    gtagTrackOpenText: {
      type: Function as PropType<() => void>,
    },
    usageNotice: {
      type: Boolean,
    },
  },

  setup(props) {
    const description = ref()
    const descriptionState = reactive({
      isOpen: false,
      height: 0,
      duration: '0.5s',
    })

    const toggleDescriptiion = () => {
      descriptionState.isOpen = descriptionState.isOpen ? false : true

      if (descriptionState.isOpen) {
        descriptionState.height = description.value.clientHeight
        if (props.gtagTrackOpenText) props.gtagTrackOpenText()
      } else {
        descriptionState.height = 0
      }
    }

    const closeDescription = () => {
      descriptionState.isOpen = false
      descriptionState.height = 0
      descriptionState.duration = '0s'
      setTimeout(() => (descriptionState.duration = '0.5s'), 500)
    }
    const contents = {
      ja: {
        close: 'とじる',
        text: 'テキストで本文を見る',
        usage:
          '<ruby>文字<rp>(</rp><rt>もじ</rt><rp>)</rp></ruby>で<ruby>読<rp>(</rp><rt>よ</rt><rp>)</rp></ruby>みたい<ruby>方<rp>(</rp><rt>かた</rt><rp>)</rp></ruby>はこちら',
      },
      en: {
        close: 'Close',
        text: 'Read the commentary in text format',
        usage: 'Please click here for the text version',
      },
      zh: {
        close: '关闭',
        text: '浏览正文文本',
        usage: '点此浏览文字说明',
      },
      ko: {
        close: '닫기',
        text: '텍스트로 본문 보기',
        usage: '글자로 읽고 싶은 분',
      },
    }

    return {
      description,
      descriptionState,
      toggleDescriptiion,
      closeDescription,
      contents,
    }
  },
})
