
import { defineComponent, reactive, PropType } from 'vue'
import Button from '@/components/elements/Button.vue'
// eslint-disable-next-line
// @ts-ignore 
import SwiperCore, { Pagination, A11y, Navigation } from 'swiper'
// eslint-disable-next-line
// @ts-ignore 
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, A11y, Navigation])

export default defineComponent({
  name: 'RequestModal',

  components: {
    Button,
    Swiper,
    SwiperSlide,
  },

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String as PropType<'ja' | 'en' | 'zh' | 'ko'>,
      default: 'ja',
    },
  },

  setup(props, context) {
    const state = reactive({
      slide: 0,
      swiper: null,
    })

    const title = {
      ja: 'お願い',
      en: 'Take Care!',
      zh: '注意',
      ko: '부탁드려요',
    }

    const contents = {
      ja: [
        {
          src: 'img/request/ja/icon_01.svg',
          alt: '展示室ではおしずかに',
        },
        {
          src: 'img/request/icon_02.svg',
          alt: '絵や額には触らないでね',
        },
        {
          src: 'img/request/ja/icon_03.svg',
          alt: '展示室で飲食はしないでね',
        },
        {
          src: 'img/request/ja/icon_04.svg',
          alt: '写真・動画撮影はしないでね',
        },
        {
          src: 'img/request/ja/icon_05.svg',
          alt: '展示は好きなところからみてね',
        },
      ],
      en: [
        {
          src: 'img/request/en/icon_01.svg',
          alt: 'Let’s be quiet inside the<br><span>exhibition spaces</span>…',
        },
        {
          src: 'img/request/en/icon_02.svg',
          alt:
            'Please don’t touch the<br><span>paintings</span> or <span>pictures</span>…',
        },
        {
          src: 'img/request/en/icon_03.svg',
          alt:
            'Please don’t<br>bring <span>food</span> or <span>drink</span><br>into the exhibition spaces…',
        },
        {
          src: 'img/request/en/icon_04.svg',
          alt:
            'Please don’t take<br><span>photographs</span> or <span>videos</span>…',
        },
        {
          src: 'img/request/en/icon_05.svg',
          alt: 'Enjoy the exhibits<br><span>in any order you like</span>!',
        },
      ],
      zh: [
        {
          src: 'img/request/zh/icon_01.svg',
          alt: '<span>在展厅要保持安静</span>',
        },
        {
          src: 'img/request/zh/icon_02.svg',
          alt: '不要触碰绘<span>画</span>或<span>画框哦</span>',
        },
        {
          src: 'img/request/zh/icon_03.svg',
          alt: '不要在展厅内饮<span>食哦</span>',
        },
        {
          src: 'img/request/zh/icon_04.svg',
          alt: '不要<span>拍照</span>或<span>录影哦</span>',
        },
        {
          src: 'img/request/zh/icon_05.svg',
          alt: '<span>从自己喜欢的地方</span>看起吧',
        },
      ],
      ko: [
        {
          src: 'img/request/ko/icon_01.svg',
          alt: '<span>전시실</span>에서는 조용히',
        },
        {
          src: 'img/request/ko/icon_02.svg',
          alt: '<span>그림</span>이나 <span>액자</span>를 만지지 마세요',
        },
        {
          src: 'img/request/ko/icon_03.svg',
          alt: '전시실에서는 <span>음식을 먹지</span> 마세요',
        },
        {
          src: 'img/request/ko/icon_04.svg',
          alt: '<span>사진</span>이나 <span>동영상</span>을 찍지 마세요',
        },
        {
          src: 'img/request/ko/icon_05.svg',
          alt: '전시는 <span>마음에 드는 것부터</span> 보세요',
        },
      ],
    }
    const btnText = {
      ja: {
        next: '次へ',
        start: 'スタート！',
      },
      en: {
        next: 'Next',
        start: 'Start！',
      },
      zh: {
        next: '下一个',
        start: '开始！',
      },
      ko: {
        next: '다음',
        start: '시작!',
      },
    }

    // eslint-disable-next-line
    const onSwiper = (sw: any) => {
      state.swiper = sw
    }
    // eslint-disable-next-line
    const onSlideChange = (sw: any) => {
      state.slide = sw.activeIndex
    }

    const handleSlide = () => {
      // eslint-disable-next-line
        // @ts-ignore 
      if (state.slide === contents[props.lang].length - 1) {
        context.emit('close')
        return
      }
      if (state.swiper) {
        // eslint-disable-next-line
        // @ts-ignore 
        state.swiper.slideTo(state.slide + 1)
      }
    }

    return {
      state,
      title,
      contents,
      btnText,
      handleSlide,
      onSlideChange,
      onSwiper,
    }
  },
})
