<template>
  <button
    type="button"
    class="audioBtn"
    :class="[
      colorClass,
      { 'is-listen': isListen },
      { 'is-quiz': quiz },
      { 'is-text': text },
    ]"
    @click="handleClick"
  >
    <div class="audioBtn-icon">
      <img src="img/common/sound_white.svg" alt="" v-if="quiz" />
      <img :src="`img/common/sound_${color}.svg`" alt="" v-else />
    </div>
    <div class="audioBtn-num">
      <SvgQuizText v-if="quiz" :num="num" :lang="lang" />
      <SvgNumber
        v-else-if="text"
        :num="num"
        :styles="{ height: '22px', width: 'auto' }"
      />
      <SvgNumber v-else :num="num" />
    </div>
    <div class="audioBtn-text" :class="`-${lang}`" v-if="text">
      <img :src="`img/main/${lang}/greeting.svg`" :alt="text" />
    </div>
    <div class="audioBtn-star" v-if="isListen">
      <img :src="`img/common/star_${color}.svg`" :alt="String(num)" />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import SvgNumber from '@/components/elements/SvgNumber.vue'
import SvgQuizText from '@/components/elements/SvgQuizText.vue'

export default defineComponent({
  name: 'AudioButton',

  components: {
    SvgNumber,
    SvgQuizText,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    num: {
      type: Number,
      required: true,
    },
    color: {
      validator: (value: string) => {
        return ['primary', 'secondary', 'yellow'].includes(value)
      },
      default: 'primary',
    },
    isListen: {
      type: Boolean,
      default: false,
    },
    quiz: {
      type: Boolean,
      default: false,
    },
    handleClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    lang: {
      validator: (value: string) => {
        return ['ja', 'en', 'zh', 'ko'].includes(value)
      },
      default: 'ja',
    },
  },

  setup(props) {
    return {
      colorClass: `audioBtn-color-${props.color}`,
    }
  },
})
</script>

<style lang="scss" scoped>
.audioBtn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 130px;
  height: 130px;
  font-size: 2.2rem;
  font-weight: 700;
  border-radius: 50%;
  background-color: #fff;
  line-height: 1;
  cursor: pointer;
  border: 8px solid transparent;
  @media screen and (max-width: 320px) {
    width: 120px;
    height: 120px;
  }
  &.is-quiz {
    width: 110px;
    height: 110px;
    .audioBtn-icon {
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }
  &.is-listen {
    .audioBtn-star {
      position: absolute;
      left: -8px;
      top: -8px;
      width: 36px;
      height: 36px;
    }
  }
  &.is-text {
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;
    border-radius: 35px;
    box-shadow: 0 0 16px rgba(#333, 0.2);
    .audioBtn-icon {
      margin-left: 0;
      margin-bottom: 0;
      margin-right: 16px;
    }
    .audioBtn-num {
      margin-right: 12px;
      img {
        height: 22px;
        width: auto;
      }
    }
    .audioBtn-star {
      left: -18px;
      top: -18px;
    }
  }
}
.audioBtn-icon {
  margin-left: 7px;
  margin-bottom: 15px;
}
.audioBtn-text {
  line-height: 1;

  &.-en {
    transform: translateY(2px);
  }
}
.audioBtn-color {
  &-primary {
    box-shadow: 0 0 16px rgba(#00445c, 0.2);
    &.is-quiz {
      border-color: transparent;
      background-color: var.$color-primary;
    }
    &.is-listen {
      border-color: var.$color-primary-light;
    }
  }

  &-secondary {
    box-shadow: 0 0 16px rgba(#f9838d, 0.2);
    &.is-quiz {
      border-color: transparent;
      background-color: var.$color-secondary;
    }
    &.is-listen {
      border-color: var.$color-secondary-light;
    }
  }

  &-yellow {
    box-shadow: 0 0 16px rgba(#d18f1e, 0.2);
    &.is-quiz {
      border-color: transparent;
      background-color: var.$color-yellow;
    }
    &.is-listen {
      border-color: var.$color-yellow-light;
    }
  }
}
</style>
