<template>
  <div
    class="splash"
    :class="{ isHide: state.hideSplashStart }"
    v-if="!state.hideSplash"
    @click="hideSplashStart"
  >
    <div
      class="loading_contents"
      :class="{ isLoaded: state.loaded }"
      v-if="!state.loadAnimFinish"
    >
      <h1 class="loading_logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="94.15"
          viewBox="0 0 94 94.15"
        >
          <title>Logo</title>
          <path
            d="M205.725,326.265l-4.265-14.478,0,0h0l-10.375-35.2a10,10,0,0,1,6.765-12.412l42.078-12.4h0c1.556-.46,1.03-1.03.275-1.313a52.2,52.2,0,0,0-14.55-2.061c-25.952,0-47,18.811-47,42.015,0,15.933,9.922,29.79,24.546,36.91.07.037.14.065.212.1.356.169.71.327,1.078.494,1.114.456,1.854.441,1.236-1.649Zm54.785-64.038c-.7-.694-.975-.6-1.153-.194-.075.243-.15.481-.236.71-.011.038-.022.079-.036.118l0,0a9.989,9.989,0,0,1-6.506,6.025s-23.1,6.808-36.414,10.737c-1.988.586-2.4,3.2-1.978,4.615.665,2.276,1.462,4.976,2.343,7.955,1.078,3.316,2.55,3.969,5.5,3.111l29.6-8.724a10,10,0,1,1,5.657,19.176l-30.69,9.042c-1.986.594-2.477,3.132-2.06,4.545,1.359,4.619,2.67,9.061,3.832,13.009l2.1,7.141c.192.649.37,1.261.543,1.84.3,1.011,1.318,2.113,2.128.043v-.007l3.238-8.267a3.108,3.108,0,0,1,2.343-2.325.339.339,0,0,0,.048-.018c19.579-5.075,33.89-21.217,33.89-40.346A39.505,39.505,0,0,0,260.511,262.227Z"
            transform="translate(-178.657 -248.394)"
            fill="#66ccf0"
          />
        </svg>
      </h1>
      <p class="loading_text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="78.274"
          height="12.18"
          viewBox="0 0 78.274 12.18"
        >
          <g transform="translate(-154.703 -393.28)">
            <title>Loading</title>
            <path
              d="M4.326-10.514c0-.994-.56-1.358-1.582-1.358-1.526,0-1.54.994-1.54,1.358v8.386C1.2-.658,1.806,0,3.332,0H8.3c.8,0,1.3-.378,1.3-1.386,0-1.33-.924-1.372-1.3-1.372H4.62a.266.266,0,0,1-.294-.294Zm12.838-1.358c-2.366,0-5.628,1.232-5.628,6.2,0,3.962,2.506,5.978,5.628,5.978,2.576,0,5.614-1.47,5.614-6.09C22.778-10.136,20.02-11.872,17.164-11.872Zm0,2.814c2.408,0,2.408,2.716,2.408,3.276,0,.588,0,3.29-2.408,3.29s-2.422-2.7-2.422-3.29S14.742-9.058,17.164-9.058ZM27.9-1.988h4.27L32.662-.6a1.192,1.192,0,0,0,1.064.91c.63,0,1.89-.378,1.89-1.316a1.855,1.855,0,0,0-.112-.616L32.48-10.052a2.324,2.324,0,0,0-2.464-1.82,2.366,2.366,0,0,0-2.422,1.82l-3.01,8.428a1.809,1.809,0,0,0-.126.63c0,.826,1.148,1.3,1.876,1.3A1.166,1.166,0,0,0,27.412-.6Zm1.9-5.306c.1-.28.154-.322.238-.322s.126.042.224.322l.924,2.59h-2.31ZM39.9-11.564c-1.274,0-2.128.434-2.128,2.128v7.308C37.772-.644,38.388,0,39.9,0h2.87c4.564,0,5-4.06,5-5.754,0-1.988-.56-5.81-5-5.81Zm2.548,2.772c1.652,0,2.114,1.61,2.114,3.024,0,.6-.084,3.01-2.114,3.01H41.174c-.2,0-.28-.112-.28-.294v-5.46a.251.251,0,0,1,.28-.28ZM53.48-10.514c0-.994-.56-1.358-1.582-1.358-1.526,0-1.54.994-1.54,1.358V-1.05c0,.994.56,1.358,1.582,1.358,1.526,0,1.54-.994,1.54-1.358Zm9.464,5.376L63.112-4h-.028l-2.366-6.79c-.35-1.022-1.372-1.078-2.128-1.078-1.47,0-2.044.5-2.044,1.372v9.45c0,.994.56,1.358,1.582,1.358,1.512,0,1.54-.98,1.54-1.358V-6.426L59.5-7.56h.028L61.88-.77C62.23.238,63.28.308,64.022.308c1.47,0,2.044-.5,2.044-1.358v-9.464c0-.994-.56-1.358-1.582-1.358-1.512,0-1.54.98-1.54,1.358Zm12.964-1.4c-.742,0-1.26.308-1.26,1.3a1.127,1.127,0,0,0,1.26,1.26c.112,0,.252,0,.252.14a1.926,1.926,0,0,1-1.946,1.344c-1.176,0-2.31-.826-2.31-3.22,0-1.708.5-3.346,2.422-3.346a1.98,1.98,0,0,1,2.086,1.274c.2.518.336.9,1.428.9,1.442,0,1.526-1.05,1.526-1.274,0-1.5-1.876-3.71-5-3.71-3.556,0-5.67,2.534-5.67,6.1,0,3.794,2.394,6.076,5.488,6.076a3.519,3.519,0,0,0,2.7-1.162A1.119,1.119,0,0,0,78.176.308a1.143,1.143,0,0,0,1.3-1.12v-3.6c0-1.484-.644-2.128-2.128-2.128Z"
              transform="translate(153.499 405.152)"
              fill="#66ccf0"
            />
          </g>
        </svg>
      </p>
      <div class="loading_bar">
        <div
          class="loading_barItem"
          :style="{ width: `${state.loadbar}%` }"
        ></div>
      </div>
    </div>
    <div class="splash_contents">
      <div class="splash_logo" :class="{ isShow: state.showLogo }">
        <img :src="`img/splash/${lang}/logo_02.svg`" :alt="alt[lang]" />
      </div>
      <div class="splash_main" :class="{ isBound: state.charaBound }">
        <div class="splash_img1" :class="{ isShow: state.animation1 }">
          <img src="img/splash/img_01.png" alt="" />
        </div>
        <div class="splash_img2" :class="{ isShow: state.animation2 }">
          <img src="img/splash/img_02.png" alt="" />
        </div>
        <div class="splash_img3" :class="{ isShow: state.animation3 }">
          <img src="img/splash/img_03.png" alt="" />
        </div>
        <div class="splash_img4" :class="{ isShow: state.animation4 }">
          <img src="img/splash/img_04.png" alt="" />
        </div>
        <div class="splash_img5" :class="{ isShow: state.animation5 }">
          <img src="img/splash/img_05.png" alt="" />
        </div>
        <div class="splash_img6" :class="{ isShow: state.animation6 }">
          <img src="img/splash/img_06.png" alt="" />
        </div>
        <div class="splash_img7" :class="{ isShow: state.animation7 }">
          <img src="img/splash/img_07.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

export default defineComponent({
  name: 'Splash',

  props: {
    completeLoadContents: {
      type: Function as PropType<() => void>,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
  },

  setup(props) {
    const isShownOpening = Cookies.get(app_cookie.is_shown_opening)
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      loaded: false,
      loadAnimFinish: false,
      animation1: false,
      animation2: false,
      animation3: false,
      animation4: false,
      animation5: false,
      animation6: false,
      animation7: false,
      showLogo: false,
      charaBound: false,
      hideSplashStart: false,
      hideSplash: false,
      loadbar: 0,
    })

    const hideSplashStart = () => {
      if (state.charaBound && !state.hideSplashStart) {
        state.hideSplashStart = true
        setTimeout(() => {
          state.hideSplash = true
          Cookies.set(app_cookie.is_shown_opening, '1', {
            expires: 1 / 2,
          })
          store.commit('initializedApp')
        }, 400)
      }
    }

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms))

    const asyncFunc = async () => {
      // Cookieがあればスキップ
      if (isShownOpening) {
        state.hideSplashStart = true
        setTimeout(() => {
          state.hideSplash = true
          store.commit('initializedApp')
        }, 400)
      } else {
        // オープニングアニメーション
        gtag('event', 'animation')
        state.loaded = true
        await sleep(1000)
        state.loadAnimFinish = true
        state.animation1 = true
        await sleep(400)
        state.animation2 = true
        state.animation3 = true
        state.animation4 = true
        state.animation6 = true
        await sleep(400)
        state.animation7 = true
        await sleep(300)
        state.animation5 = true
        await sleep(450)
        state.showLogo = true
        await sleep(800)
        state.charaBound = true
        await sleep(3000)
        hideSplashStart()
      }
    }

    const loadImages = new Promise((resolve, reject) => {
      try {
        let isComplete = false
        let counter = 0
        const images = [
          'img/auth/item_01.png',
          'img/auth/item_02.png',
          'img/auth/item_03.png',
          'img/auth/item_04.png',
          'img/auth/item_05.png',
          'img/auth/item_06.png',
          'img/auth/num_01.svg',
          'img/auth/num_02.svg',
          'img/auth/num_03.svg',
          `img/auth/${props.lang}/title.svg`,
          `img/auth/${props.lang}/error.svg`,
          'img/main/img_animals_room.png',
          'img/main/img_exhibition_room_01.png',
          'img/main/img_exhibition_room_02.png',
          'img/main/img_fujiko_room.png',
          'img/main/img_lumberjack_fountain.png',
          'img/main/mv.png',
          'img/main/dot_title.svg',
          'img/main/floor_animals_room.svg',
          'img/main/floor_exhibition_room_01.svg',
          'img/main/floor_exhibition_room_02.svg',
          'img/main/floor_fujiko_room.svg',
          'img/main/floor_fujiko_sunday.svg',
          'img/main/floor_history_load.svg',
          'img/main/floor_lumberjack_fountain.svg',
          'img/main/logo_f.svg',
          `img/main/${props.lang}/title_animals_room.svg`,
          `img/main/${props.lang}/title_exhibition_room_01.svg`,
          `img/main/${props.lang}/title_exhibition_room_02.svg`,
          `img/main/${props.lang}/title_fujiko_room.svg`,
          `img/main/${props.lang}/title_fujiko_sunday.svg`,
          `img/main/${props.lang}/title_history_load.svg`,
          `img/main/${props.lang}/title_lumberjack_fountain.svg`,
          `img/main/${props.lang}/title.svg`,
          `img/main/${props.lang}/greeting.svg`,
          `img/request/icon_01.svg`,
          `img/request/icon_02.svg`,
          `img/request/icon_03.svg`,
          `img/request/icon_04.svg`,
          `img/request/icon_05.svg`,
          `img/request/${props.lang}/title.svg`,
          'img/common/arrow_down.svg',
          'img/common/arrow_left.svg',
          'img/common/arrow_up.svg',
          'img/common/close.svg',
          'img/common/dot_blue.svg',
          'img/common/dot_pink.svg',
          'img/common/dot_yellow.svg',
          'img/common/loading.svg',
          'img/common/next.svg',
          'img/common/pause.svg',
          'img/common/play.svg',
          'img/common/prev.svg',
          'img/common/quiz_bg.svg',
          'img/common/sound_primary.svg',
          'img/common/sound_secondary.svg',
          'img/common/sound_white.svg',
          'img/common/sound_yellow.svg',
          'img/common/star_primary.svg',
          'img/common/star_secondary.svg',
          'img/common/star_yellow.svg',
          'img/common/stripe_blue.svg',
          'img/common/stripe_pink.svg',
          'img/common/stripe_yellow.svg',
          'img/common/wave_section.png',
          'img/quiz/miss_img.png',
          'img/quiz/right_img.png',
          'img/quiz/wave_quiz.png',
          `img/quiz/${props.lang}/miss.svg`,
          `img/quiz/${props.lang}/right.svg`,
          'img/quiz/wave_quiz.svg',
          'img/splash/img_01.png',
          'img/splash/img_02.png',
          'img/splash/img_03.png',
          'img/splash/img_04.png',
          'img/splash/img_05.png',
          'img/splash/img_06.png',
          'img/splash/img_07.png',
          `img/splash/${props.lang}/logo_02.svg`,
          'img/splash/bg_01.svg',
        ]
        const loadedAction = () => {
          counter++
          state.loadbar = state.loadbar + 100 / images.length
          if (counter >= images.length) {
            state.loadbar = 100
            isComplete = true
            return resolve('')
          }
        }
        for (let i in images) {
          const img = document.createElement('img')
          img.src = images[i]
          img.addEventListener('load', loadedAction)
        }

        // ローディングに1分以上かかったらタイムアウト
        setTimeout(() => {
          if (!isComplete) return reject()
        }, 60000)
      } catch (error) {
        return reject()
      }
    })

    const alt = {
      ja: '川崎市 藤子・F・不二雄ミュージアム おはなしデンワ',
      en: '川崎市 藤子・F・不二雄ミュージアム Gabby Guide',
      zh: '川崎市 藤子・F・不二雄ミュージアム 解说电话',
      ko: '川崎市 藤子・F・不二雄ミュージアム 이야기 전화',
    }

    Promise.all([loadImages, store.dispatch('getContents', route.params.lang)])
      .then(() => {
        asyncFunc()
        props.completeLoadContents()
      })
      .catch(() => {
        store.dispatch('showErrorModal', 'contents')
      })

    return {
      state,
      hideSplashStart,
      alt,
    }
  },
})
</script>

<style lang="scss" scoped>
@keyframes loading_bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes bound {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: #fff;
  transition: opacity 0.8s ease;
  backface-visibility: hidden;
  opacity: 1;

  &.isHide {
    opacity: 0;
  }
}
.loading_contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  &.isLoaded {
    .loading_logo {
      opacity: 0;
      transform: scale(7, 7);
    }
    .loading_bar,
    .loading_text {
      opacity: 0;
    }
  }
}
.loading_logo {
  width: 80px;
  opacity: 1;
  transition: 1s;
  backface-visibility: hidden;
}
.loading_text {
  width: 80px;
  margin-top: 40px;
  transition: 0.4s;
  line-height: 0;
}
.loading_bar {
  width: 80px;
  height: 4px;
  background-color: #dbdbdb;
  border-radius: 4px;
  margin-top: 10px;
  transition: 0.4s;
  backface-visibility: hidden;
  &Item {
    width: 0;
    height: 4px;
    border-radius: 4px;
    background-color: #66ccf0;
    backface-visibility: hidden;
    // animation-name: loading_bar;
    // animation-duration: 2s;
    // animation-fill-mode: forwards;
  }
}
.splash_contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff url('/img/splash/bg_01.svg');
}
.splash_logo {
  margin-right: 9.2vw;
  margin-left: 9.2vw;
  padding-bottom: 15px;
  text-align: center;
  opacity: 0;
  transition: 0.4s ease;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
  }
}
.splash_main {
  margin-top: 15px;
  width: 265px;
  height: 356px;
  position: relative;
  &.isBound {
    backface-visibility: hidden;
    animation-name: bound;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
}
.splash_img1 {
  width: 191px;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 28px;
  opacity: 0;
  transition: 0.4s ease;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
  }
}
.splash_img2 {
  width: 59px;
  position: absolute;
  z-index: 3;
  left: 28px;
  top: 180px;
  opacity: 0;
  transition: 0.4s ease;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
    left: 8px;
  }
}
.splash_img3 {
  width: 69px;
  position: absolute;
  z-index: 3;
  left: 31px;
  bottom: 25px;
  opacity: 0;
  transition: 0.4s ease;
  transition-delay: 0.1s;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
    bottom: 5px;
  }
}
.splash_img4 {
  width: 64px;
  position: absolute;
  z-index: 3;
  left: 120px;
  bottom: 20px;
  opacity: 0;
  transition: 0.4s ease;
  transition-delay: 0.2s;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
    left: 128px;
    bottom: 0;
  }
}
.splash_img5 {
  width: 248px;
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 10px;
  opacity: 0;
  transition: 0.4s ease;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
    top: 0;
  }
}
.splash_img6 {
  width: 61px;
  position: absolute;
  z-index: 3;
  right: 12px;
  bottom: 26px;
  opacity: 0;
  transition: 0.4s ease;
  transition-delay: 0.3s;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
    right: 8px;
    bottom: 20px;
  }
}
.splash_img7 {
  width: 223px;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 52px;
  opacity: 0;
  transition: 0.4s ease;
  backface-visibility: hidden;
  &.isShow {
    opacity: 1;
    top: 44px;
  }
}
</style>
