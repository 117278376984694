
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, ref } from 'vue'
import AudioController from '@/components/block/AudioController.vue'
import AudioMeta from '@/components/block/AudioMeta.vue'
import Button from '@/components/elements/Button.vue'
import QuizAnswerButton from '@/components/elements/QuizAnswerButton.vue'
import SvgQuizText from '@/components/elements/SvgQuizText.vue'
import SvgTextQuizTitle from '@/components/elements/SvgTextQuizTitle.vue'

interface Answer {
  correct: string
  option_a: string
  option_b: string
}

interface Comment {
  audio: string
  description: string
}

type Mode = 'answer' | 'right' | 'miss'

export default defineComponent({
  name: 'Quiz',

  components: {
    AudioController,
    AudioMeta,
    Button,
    QuizAnswerButton,
    SvgQuizText,
    SvgTextQuizTitle,
  },

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    quizQuestion: {
      type: String,
      required: true,
    },
    quizDescription: {
      type: String,
      required: true,
    },
    quizAnswer: {
      type: Object as PropType<Answer>,
      required: true,
    },
    quizComment: {
      type: Object as PropType<Comment>,
      required: true,
    },
    currentQuiz: {
      type: Number,
      required: true,
    },
    totalTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    play: {
      type: Function as PropType<() => void>,
      required: true,
    },
    pause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
    answerdQuizCorrectly: {
      type: Function as PropType<() => void>,
      required: true,
    },
    returnQuizView: {
      type: Function as PropType<() => void>,
      required: true,
    },
    setCurrentTime: {
      type: Function as PropType<(time: number) => void>,
      required: true,
    },
  },

  setup(props) {
    const quizMeta = ref()
    const answerMeta = ref()
    const quizeStatus = ref<Mode>('answer')

    const answerQuiz = (option: 'a' | 'b') => {
      props.pause()

      if (props.quizAnswer.correct === option) {
        quizeStatus.value = 'right'
        props.answerdQuizCorrectly()
        gtag('event', `quiz_correct_${props.currentQuiz}`)
      } else {
        quizeStatus.value = 'miss'
        gtag('event', `quiz_incorrect_${props.currentQuiz}`)
      }
    }

    const closeQuiz = (): void => {
      quizeStatus.value = 'answer'
      quizMeta.value.closeDescription()
      answerMeta.value.closeDescription()
      props.close()
    }

    const clickBackBtn = (): void => {
      props.pause()

      if (quizeStatus.value === 'answer') {
        closeQuiz()
      } else {
        quizeStatus.value = 'answer'
        props.returnQuizView()
      }
    }

    const contents = {
      ja: {
        back: '戻る',
        right: 'せいかい',
        backMain: 'おはなしデンワにもどる',
        miss: 'ざんねん',
        challenge: 'もういちどチャレンジ',
      },
      en: {
        back: 'Return',
        right: 'That’s right! ',
        backMain: 'Return to the Gabby Guide',
        miss: 'That’s too bad',
        challenge: 'Want to try again?',
      },
      zh: {
        back: '返回',
        right: '回答正确！',
        backMain: '返回解说电话',
        miss: '好遗憾！',
        challenge: '再试一次！',
      },
      ko: {
        back: '뒤로 가기',
        right: '정답!',
        backMain: '이야기 전화로 돌아가기',
        miss: '아까워라!',
        challenge: '다시 도전!',
      },
    }

    return {
      quizMeta,
      answerMeta,
      quizeStatus,
      answerQuiz,
      closeQuiz,
      clickBackBtn,
      contents,
    }
  },
})
