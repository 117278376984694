
declare let gtag: Function // eslint-disable-line
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

interface Key {
  current: number
  selected: number[]
}

interface State {
  isError: boolean
}

export default defineComponent({
  name: 'Auth',

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
  },

  setup(props, { emit }) {
    if (props.isShow) gtag('event', 'key')

    const store = useStore()

    const key = reactive<Key>({
      current: 0,
      selected: [0, 0, 0],
    })
    const state = reactive<State>({
      isError: false,
    })

    const alt = {
      title: {
        ja: '今日のカギ ※カギは毎日変わるよ',
        en: 'Todays Key *The key changes every day!',
        zh: '今日秘钥 ※秘钥每天都不一样哦',
        ko: '오늘의 열쇠 ※열쇠는 매일 바뀐답니다',
      },
      error: {
        ja: 'もういちどやりなおしてね',
        en: '',
        zh: '',
        ko: '',
      },
    }

    const authenticateKey = () => {
      const selectedKey = key.selected.join('')
      state.isError = false
      store.commit('startLoading')
      store
        .dispatch('authenticateKey', selectedKey)
        .then((result: boolean) => {
          if (result) {
            Cookies.set(app_cookie.todays_key, selectedKey, {
              expires: 1 / 2,
            })
            emit('success')
          } else {
            const useAgent = navigator.userAgent.toLowerCase()
            if (useAgent.indexOf('android') > -1) {
              window.navigator.vibrate([100, 100, 100, 100, 100])
            }
            state.isError = true

            key.current = 0
            key.selected = [0, 0, 0]

            gtag('event', 'key_fault')
          }
          store.commit('endLoading')
        })
        .catch(() => {
          key.current = 0
          key.selected = [0, 0, 0]
          store.dispatch('showErrorModal', 'auth')
          store.commit('endLoading')
        })
    }

    const selectKey = (n: number) => {
      key.selected[key.current] = n
      key.current++

      if (key.current >= 3) {
        authenticateKey()
      }
    }

    const resetKey = (index: number) => {
      if (key.selected[0] === 0 && index === 1) {
        return
      }
      key.current = index

      if (index === 0) {
        key.selected = [0, 0, 0]
      } else if (index === 1) {
        key.selected[1] = 0
        key.selected[2] = 0
      }
    }

    return {
      key,
      state,
      authenticateKey,
      selectKey,
      resetKey,
      alt,
    }
  },
})
