<template>
  <div class="quiz" :class="{ '-show': isShow }">
    <div class="quizHeader">
      <button type="button" class="quizHeader_back" @click="clickBackBtn">
        <img src="/img/common/arrow_left.svg" alt="" />
        <span>{{ contents[lang].back }}</span>
      </button>
      <SvgQuizText
        :lang="lang"
        :num="currentQuiz"
        fill="#333"
        :styles="{ height: '22px', width: 'auto' }"
      />
    </div>

    <div class="quizCont" v-show="quizeStatus !== 'right'">
      <div class="quizCont_title"><SvgTextQuizTitle :lang="lang" /></div>
      <AudioMeta
        :lang="lang"
        ref="quizMeta"
        :audioName="quizQuestion || ''"
        :audioDescription="quizDescription || ''"
      />
      <AudioController
        :lang="lang"
        :totalTime="totalTime"
        :currentTime="currentTime"
        :setCurrentTime="setCurrentTime"
        :isPlaying="isPlaying"
        :play="play"
        :pause="pause"
      />
      <div class="quizCont_btn">
        <QuizAnswerButton
          answer="a"
          :text="quizAnswer.option_a"
          :answerQuiz="answerQuiz"
        />
        <QuizAnswerButton
          answer="b"
          :text="quizAnswer.option_b"
          :answerQuiz="answerQuiz"
        />
      </div>
    </div>

    <div v-show="quizeStatus === 'right'">
      <div class="quizAnswer">
        <div class="quizAnswer_head -right">
          <img
            :src="`/img/quiz/${lang}/right.svg`"
            class="quizAnswer_icon"
            :alt="contents[lang].right"
          />
          <img
            src="/img/quiz/right_img.png"
            class="quizAnswer_img -right"
            alt=""
          />
        </div>
        <div class="quizAnswer_cnt">
          <AudioMeta
            :lang="lang"
            ref="answerMeta"
            :audioName="quizQuestion || ''"
            :showName="false"
            :audioDescription="quizComment.description || ''"
          />
          <AudioController
            :lang="lang"
            :totalTime="totalTime"
            :currentTime="currentTime"
            :setCurrentTime="setCurrentTime"
            :isPlaying="isPlaying"
            :play="play"
            :pause="pause"
          />
          <div class="quizAnswer_btn -right">
            <Button
              color="primary"
              shodow="white"
              size="sm"
              :text="contents[lang].backMain"
              :handleClick="closeQuiz"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="quizModal" v-show="quizeStatus === 'miss'">
      <div class="quizAnswer">
        <div class="quizAnswer_head -miss">
          <img
            :src="`/img/quiz/${lang}/miss.svg`"
            class="quizAnswer_icon"
            :alt="contents[lang].miss"
          />
          <img
            src="/img/quiz/miss_img.png"
            class="quizAnswer_img -miss"
            alt=""
          />
        </div>
        <div class="quizAnswer_cnt">
          <div class="quizAnswer_btn">
            <Button
              color="primary"
              shodow="white"
              size="sm"
              :text="contents[lang].challenge"
              :handleClick="() => (quizeStatus = 'answer')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, ref } from 'vue'
import AudioController from '@/components/block/AudioController.vue'
import AudioMeta from '@/components/block/AudioMeta.vue'
import Button from '@/components/elements/Button.vue'
import QuizAnswerButton from '@/components/elements/QuizAnswerButton.vue'
import SvgQuizText from '@/components/elements/SvgQuizText.vue'
import SvgTextQuizTitle from '@/components/elements/SvgTextQuizTitle.vue'

interface Answer {
  correct: string
  option_a: string
  option_b: string
}

interface Comment {
  audio: string
  description: string
}

type Mode = 'answer' | 'right' | 'miss'

export default defineComponent({
  name: 'Quiz',

  components: {
    AudioController,
    AudioMeta,
    Button,
    QuizAnswerButton,
    SvgQuizText,
    SvgTextQuizTitle,
  },

  props: {
    lang: {
      type: String,
      default: 'ja',
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    quizQuestion: {
      type: String,
      required: true,
    },
    quizDescription: {
      type: String,
      required: true,
    },
    quizAnswer: {
      type: Object as PropType<Answer>,
      required: true,
    },
    quizComment: {
      type: Object as PropType<Comment>,
      required: true,
    },
    currentQuiz: {
      type: Number,
      required: true,
    },
    totalTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    play: {
      type: Function as PropType<() => void>,
      required: true,
    },
    pause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
    answerdQuizCorrectly: {
      type: Function as PropType<() => void>,
      required: true,
    },
    returnQuizView: {
      type: Function as PropType<() => void>,
      required: true,
    },
    setCurrentTime: {
      type: Function as PropType<(time: number) => void>,
      required: true,
    },
  },

  setup(props) {
    const quizMeta = ref()
    const answerMeta = ref()
    const quizeStatus = ref<Mode>('answer')

    const answerQuiz = (option: 'a' | 'b') => {
      props.pause()

      if (props.quizAnswer.correct === option) {
        quizeStatus.value = 'right'
        props.answerdQuizCorrectly()
        gtag('event', `quiz_correct_${props.currentQuiz}`)
      } else {
        quizeStatus.value = 'miss'
        gtag('event', `quiz_incorrect_${props.currentQuiz}`)
      }
    }

    const closeQuiz = (): void => {
      quizeStatus.value = 'answer'
      quizMeta.value.closeDescription()
      answerMeta.value.closeDescription()
      props.close()
    }

    const clickBackBtn = (): void => {
      props.pause()

      if (quizeStatus.value === 'answer') {
        closeQuiz()
      } else {
        quizeStatus.value = 'answer'
        props.returnQuizView()
      }
    }

    const contents = {
      ja: {
        back: '戻る',
        right: 'せいかい',
        backMain: 'おはなしデンワにもどる',
        miss: 'ざんねん',
        challenge: 'もういちどチャレンジ',
      },
      en: {
        back: 'Return',
        right: 'That’s right! ',
        backMain: 'Return to the Gabby Guide',
        miss: 'That’s too bad',
        challenge: 'Want to try again?',
      },
      zh: {
        back: '返回',
        right: '回答正确！',
        backMain: '返回解说电话',
        miss: '好遗憾！',
        challenge: '再试一次！',
      },
      ko: {
        back: '뒤로 가기',
        right: '정답!',
        backMain: '이야기 전화로 돌아가기',
        miss: '아까워라!',
        challenge: '다시 도전!',
      },
    }

    return {
      quizMeta,
      answerMeta,
      quizeStatus,
      answerQuiz,
      closeQuiz,
      clickBackBtn,
      contents,
    }
  },
})
</script>

<style scoped lang="scss">
.quiz {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  background-image: url(/img/common/quiz_bg.svg);
  padding: 0 20px 20px;
  overflow-y: auto;
  visibility: hidden;

  &.-show {
    visibility: visible;
  }
}

.quizHeader {
  width: 100%;
  height: 70px;
  position: relative;
  text-align: center;
  padding-top: 18px;

  &_back {
    height: 22px;
    position: absolute;
    left: 0;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 22px;
    display: flex;
    align-items: center;

    span {
      display: block;
      margin-left: 10px;
      transform: translateY(-1px);
    }
  }
}

.quizCont {
  background-color: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 30px 15px 20px;
  position: relative;

  &_title {
    width: 100px;
    height: 28px;
    border-radius: 14px;
    background-color: var.$color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -50px;
    margin-top: -14px;

    svg {
      display: block;
    }
  }

  &_btn {
    margin-top: 30px;
  }
}

.quizModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(33, 33, 33, 0.6);
  display: flex;
  align-items: center;

  &_box {
    width: 100%;
    background-color: #fff;
  }
}

.quizAnswer {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;

  &_head {
    padding: 20px 20px 36px;
    position: relative;
    background-color: #e5f4f8;
    background-image: url('/img/quiz/wave_quiz.png');
    background-repeat: repeat-x;
    background-position: bottom -5px center;
    background-size: 336px 26px;

    &.-right {
      background-color: #fde4e5;
    }

    &.-miss {
      background-color: #e5f4f8;
    }
  }

  &_icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &_img {
    position: absolute;
    right: 15px;

    &.-miss {
      width: 94px;
      top: 30px;
    }

    &.-right {
      width: 90px;
      top: 25px;
    }
  }

  &_cnt {
    padding: 15px 15px 30px;
  }

  &_btn {
    padding-left: 30px;
    padding-right: 30px;

    &.-right {
      margin-top: 40px;
    }
  }
}
</style>
