<template>
  <div class="quizAnswerButton">
    <span class="quizAnswerButton_option">
      <svg
        v-if="answer === 'a'"
        xmlns="http://www.w3.org/2000/svg"
        width="15.74"
        height="17.18"
        viewBox="0 0 15.74 17.18"
      >
        <path
          d="M-3.47-3.34H3.47L4.45-.68c.2.56.58,1.12,1.24,1.12.64,0,2.18-.4,2.18-1.54a2.234,2.234,0,0,0-.14-.76L3.11-14.44a2.975,2.975,0,0,0-3.14-2.3,2.992,2.992,0,0,0-3.08,2.3L-7.73-1.86a2.5,2.5,0,0,0-.14.82c0,.92,1.3,1.48,2.14,1.48A1.412,1.412,0,0,0-4.45-.68Zm2.98-8.14c.16-.44.32-.54.5-.54.14,0,.3.06.48.54L2.39-6.3H-2.37Z"
          transform="translate(7.87 16.74)"
          fill="#f9838d"
        />
      </svg>
      <svg
        v-if="answer === 'b'"
        xmlns="http://www.w3.org/2000/svg"
        width="13.76"
        height="16.3"
        viewBox="0 0 13.76 16.3"
      >
        <path
          d="M1.83,0C5.35,0,7.21-2.24,7.21-4.74A3.986,3.986,0,0,0,4.45-8.72a3.461,3.461,0,0,0,2.18-3.34c0-2.3-1.78-4.24-5.18-4.24H-3.71c-1.92,0-2.84.72-2.84,2.84V-2.84c0,2.1.9,2.84,2.84,2.84ZM1.09-6.92c1.2,0,2.54.36,2.54,1.9,0,1.78-1.8,2-2.52,2h-3.5a.592.592,0,0,1-.66-.66V-6.92Zm-4.14-5.7a.592.592,0,0,1,.66-.66H1.05c1.68,0,2.02.98,2.02,1.68A1.768,1.768,0,0,1,1.05-9.8h-4.1Z"
          transform="translate(6.55 16.3)"
          fill="#36afdf"
        />
      </svg>
    </span>
    <Button
      :color="answer === 'a' ? 'secondary' : 'primary'"
      shadow="white"
      :text="text"
      :handleClick="() => answerQuiz(answer)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Button from '@/components/elements/Button.vue'

export default defineComponent({
  name: 'QuizAnswerButton',

  components: {
    Button,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    answer: {
      validator: (value: string) => {
        return ['a', 'b'].includes(value)
      },
      required: true,
    },
    answerQuiz: {
      type: Function as PropType<(option: 'a' | 'b') => void>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.quizAnswerButton {
  position: relative;

  & + & {
    margin-top: 15px;
  }

  &_option {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 8px;
    left: 8px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  Button {
    text-align: left;
    padding-left: 74px;
  }
}
</style>
