
import { defineComponent, reactive, watch, ref, nextTick } from 'vue'
// eslint-disable-next-line
// @ts-ignore 
import Confetti from 'vue-confetti/src/confetti.js'
export default defineComponent({
  name: 'thanksSection',

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    bottomMargin: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: 'ja',
    },
  },

  setup(props) {
    const state = reactive({
      confetti: new Confetti(),
      thanksToTop: null,
    })
    const root = ref<HTMLDivElement>()

    watch(
      () => props.isShow,
      () => {
        startConfettiHandler()
      },
      { deep: true }
    )

    const startConfettiHandler = () => {
      state.confetti.start({
        particles: [
          {
            type: 'rect',
            size: 5,
          },
          {
            type: 'rect',
            size: 5,
          },
          {
            type: 'rect',
            size: 4,
          },
          {
            type: 'rect',
            size: 6,
          },
        ],
        defaultColors: ['#ffb101', '#5DBEE0', '#FF838D', '#299738'],
        particlesPerFrame: 0.1,
        windSpeedMax: 1.5,
        defaultDropRate: 10,
      })
      nextTick(() => {
        const rootTop = root.value?.offsetTop
        window.scroll({
          top: rootTop,
          behavior: 'smooth',
        })
      })

      setTimeout(() => {
        state.confetti.stop()
      }, 15000)
    }

    const alt = {
      ja: '全部聞いてくれてありがとう！引き続き館内をお楽しみください！',
      en: 'Wow! You listened to the whole audio guide! Thank you very much! ',
      zh: '谢谢您收听了全部解说！欢迎继续参观！',
      ko: '모두 들어 줘서 고마워요! 뮤지엄을 계속 즐겨 주세요!',
    }

    return {
      state,
      root,
      alt,
    }
  },
})
