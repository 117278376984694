
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

export default defineComponent({
  name: 'Splash',

  props: {
    completeLoadContents: {
      type: Function as PropType<() => void>,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
  },

  setup(props) {
    const isShownOpening = Cookies.get(app_cookie.is_shown_opening)
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      loaded: false,
      loadAnimFinish: false,
      animation1: false,
      animation2: false,
      animation3: false,
      animation4: false,
      animation5: false,
      animation6: false,
      animation7: false,
      showLogo: false,
      charaBound: false,
      hideSplashStart: false,
      hideSplash: false,
      loadbar: 0,
    })

    const hideSplashStart = () => {
      if (state.charaBound && !state.hideSplashStart) {
        state.hideSplashStart = true
        setTimeout(() => {
          state.hideSplash = true
          Cookies.set(app_cookie.is_shown_opening, '1', {
            expires: 1 / 2,
          })
          store.commit('initializedApp')
        }, 400)
      }
    }

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms))

    const asyncFunc = async () => {
      // Cookieがあればスキップ
      if (isShownOpening) {
        state.hideSplashStart = true
        setTimeout(() => {
          state.hideSplash = true
          store.commit('initializedApp')
        }, 400)
      } else {
        // オープニングアニメーション
        gtag('event', 'animation')
        state.loaded = true
        await sleep(1000)
        state.loadAnimFinish = true
        state.animation1 = true
        await sleep(400)
        state.animation2 = true
        state.animation3 = true
        state.animation4 = true
        state.animation6 = true
        await sleep(400)
        state.animation7 = true
        await sleep(300)
        state.animation5 = true
        await sleep(450)
        state.showLogo = true
        await sleep(800)
        state.charaBound = true
        await sleep(3000)
        hideSplashStart()
      }
    }

    const loadImages = new Promise((resolve, reject) => {
      try {
        let isComplete = false
        let counter = 0
        const images = [
          'img/auth/item_01.png',
          'img/auth/item_02.png',
          'img/auth/item_03.png',
          'img/auth/item_04.png',
          'img/auth/item_05.png',
          'img/auth/item_06.png',
          'img/auth/num_01.svg',
          'img/auth/num_02.svg',
          'img/auth/num_03.svg',
          `img/auth/${props.lang}/title.svg`,
          `img/auth/${props.lang}/error.svg`,
          'img/main/img_animals_room.png',
          'img/main/img_exhibition_room_01.png',
          'img/main/img_exhibition_room_02.png',
          'img/main/img_fujiko_room.png',
          'img/main/img_lumberjack_fountain.png',
          'img/main/mv.png',
          'img/main/dot_title.svg',
          'img/main/floor_animals_room.svg',
          'img/main/floor_exhibition_room_01.svg',
          'img/main/floor_exhibition_room_02.svg',
          'img/main/floor_fujiko_room.svg',
          'img/main/floor_fujiko_sunday.svg',
          'img/main/floor_history_load.svg',
          'img/main/floor_lumberjack_fountain.svg',
          'img/main/logo_f.svg',
          `img/main/${props.lang}/title_animals_room.svg`,
          `img/main/${props.lang}/title_exhibition_room_01.svg`,
          `img/main/${props.lang}/title_exhibition_room_02.svg`,
          `img/main/${props.lang}/title_fujiko_room.svg`,
          `img/main/${props.lang}/title_fujiko_sunday.svg`,
          `img/main/${props.lang}/title_history_load.svg`,
          `img/main/${props.lang}/title_lumberjack_fountain.svg`,
          `img/main/${props.lang}/title.svg`,
          `img/main/${props.lang}/greeting.svg`,
          `img/request/icon_01.svg`,
          `img/request/icon_02.svg`,
          `img/request/icon_03.svg`,
          `img/request/icon_04.svg`,
          `img/request/icon_05.svg`,
          `img/request/${props.lang}/title.svg`,
          'img/common/arrow_down.svg',
          'img/common/arrow_left.svg',
          'img/common/arrow_up.svg',
          'img/common/close.svg',
          'img/common/dot_blue.svg',
          'img/common/dot_pink.svg',
          'img/common/dot_yellow.svg',
          'img/common/loading.svg',
          'img/common/next.svg',
          'img/common/pause.svg',
          'img/common/play.svg',
          'img/common/prev.svg',
          'img/common/quiz_bg.svg',
          'img/common/sound_primary.svg',
          'img/common/sound_secondary.svg',
          'img/common/sound_white.svg',
          'img/common/sound_yellow.svg',
          'img/common/star_primary.svg',
          'img/common/star_secondary.svg',
          'img/common/star_yellow.svg',
          'img/common/stripe_blue.svg',
          'img/common/stripe_pink.svg',
          'img/common/stripe_yellow.svg',
          'img/common/wave_section.png',
          'img/quiz/miss_img.png',
          'img/quiz/right_img.png',
          'img/quiz/wave_quiz.png',
          `img/quiz/${props.lang}/miss.svg`,
          `img/quiz/${props.lang}/right.svg`,
          'img/quiz/wave_quiz.svg',
          'img/splash/img_01.png',
          'img/splash/img_02.png',
          'img/splash/img_03.png',
          'img/splash/img_04.png',
          'img/splash/img_05.png',
          'img/splash/img_06.png',
          'img/splash/img_07.png',
          `img/splash/${props.lang}/logo_02.svg`,
          'img/splash/bg_01.svg',
        ]
        const loadedAction = () => {
          counter++
          state.loadbar = state.loadbar + 100 / images.length
          if (counter >= images.length) {
            state.loadbar = 100
            isComplete = true
            return resolve('')
          }
        }
        for (let i in images) {
          const img = document.createElement('img')
          img.src = images[i]
          img.addEventListener('load', loadedAction)
        }

        // ローディングに1分以上かかったらタイムアウト
        setTimeout(() => {
          if (!isComplete) return reject()
        }, 60000)
      } catch (error) {
        return reject()
      }
    })

    const alt = {
      ja: '川崎市 藤子・F・不二雄ミュージアム おはなしデンワ',
      en: '川崎市 藤子・F・不二雄ミュージアム Gabby Guide',
      zh: '川崎市 藤子・F・不二雄ミュージアム 解说电话',
      ko: '川崎市 藤子・F・不二雄ミュージアム 이야기 전화',
    }

    Promise.all([loadImages, store.dispatch('getContents', route.params.lang)])
      .then(() => {
        asyncFunc()
        props.completeLoadContents()
      })
      .catch(() => {
        store.dispatch('showErrorModal', 'contents')
      })

    return {
      state,
      hideSplashStart,
      alt,
    }
  },
})
