<template>
  <svg
    v-if="num === 1"
    xmlns="http://www.w3.org/2000/svg"
    width="12.348"
    height="30.924"
    viewBox="0 0 12.348 30.924"
    :style="styles"
  >
    <path
      d="M-2.124-19.62V-2.052C-2.124.36-.72.792,1.044.792,3.636.792,3.96-.828,3.96-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3A2.114,2.114,0,0,0-6.3-19.62Z"
      transform="translate(8.388 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 2"
    xmlns="http://www.w3.org/2000/svg"
    width="20.052"
    height="30.132"
    viewBox="0 0 20.052 30.132"
    :style="styles"
  >
    <path
      d="M7.2,0a2.411,2.411,0,0,0,2.808-2.556A2.438,2.438,0,0,0,7.2-5.076H-1.044c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-6.8,0Z"
      transform="translate(10.044 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 3"
    xmlns="http://www.w3.org/2000/svg"
    width="20.376"
    height="30.924"
    viewBox="0 0 20.376 30.924"
    :style="styles"
  >
    <path
      d="M6.192-15.84a6.593,6.593,0,0,0,3.06-5.868c0-4.356-2.916-8.424-9.4-8.424-7.812,0-9.432,6.192-9.432,8.1s1.332,2.52,2.88,2.52c2.484,0,2.664-1.224,2.808-2.3.432-2.988,2.3-3.24,3.816-3.24,2.268,0,3.312,1.62,3.312,3.564,0,1.62-.5,4-3.348,4-1.332,0-2.952,0-2.952,2.3s1.62,2.3,2.952,2.3C4.1-12.888,4.1-9.468,4.1-8.46c0,3.2-2.2,4.176-4.212,4.176-3.636,0-4.068-2.628-4.1-2.952-.252-1.692-.468-2.88-3.1-2.88-2.052,0-2.88,1.188-2.88,2.592a8.693,8.693,0,0,0,1.944,5C-6.3,0-3.42.792.072.792,9.9.792,10.188-6.984,10.188-8.46A8.524,8.524,0,0,0,6.192-15.84Z"
      transform="translate(10.188 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 4"
    xmlns="http://www.w3.org/2000/svg"
    width="20.844"
    height="30.924"
    viewBox="0 0 20.844 30.924"
    :style="styles"
  >
    <path
      id="パス_849"
      data-name="パス 849"
      d="M1.116-5.94v3.888c0,2.3,1.26,2.844,3.132,2.844,2.628,0,2.916-1.62,2.916-2.844V-5.94c1.368,0,3.24,0,3.24-2.556,0-2.52-1.98-2.52-3.24-2.52V-27.072a3.143,3.143,0,0,0-3.492-3.06A4.589,4.589,0,0,0-.36-27.72L-9.684-12.348a5.313,5.313,0,0,0-.756,2.772A3.4,3.4,0,0,0-7.056-5.94Zm-6.084-5.076c-.576,0-.576-.36-.576-.5a1.209,1.209,0,0,1,.18-.5L.144-21.06a.61.61,0,0,1,.5-.324c.4,0,.468.432.468.576v9.792Z"
      transform="translate(10.44 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 5"
    xmlns="http://www.w3.org/2000/svg"
    width="20.376"
    height="30.132"
    viewBox="0 0 20.376 30.132"
    :style="styles"
  >
    <path
      d="M-9.108-14.58c-.216,2.016,1.152,2.592,2.7,2.592A3.368,3.368,0,0,0-3.96-12.96,4.265,4.265,0,0,1-.288-14.616c3.024,0,4.392,2.124,4.392,5C4.1-7.56,3.528-4.284-.36-4.284A3.818,3.818,0,0,1-4.428-7.308,2.745,2.745,0,0,0-7.416-9.144c-1.188,0-2.772.5-2.772,2.484C-10.188-5-7.92.792-.144.792c5.616,0,10.332-3.456,10.332-10.728,0-5.94-4-9.756-9.288-9.756a7.522,7.522,0,0,0-4.644,1.3l.468-4.752a1.185,1.185,0,0,1,1.3-1.116H6.264A2.411,2.411,0,0,0,9.072-26.82a2.438,2.438,0,0,0-2.808-2.52H-3.708c-2.268,0-3.924.4-4.284,3.852Z"
      transform="translate(10.188 29.34)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 6"
    xmlns="http://www.w3.org/2000/svg"
    width="20.376"
    height="30.924"
    viewBox="0 0 20.376 30.924"
    :style="styles"
  >
    <path
      d="M-3.924-17.568c.324-7.02,2.988-7.488,4.716-7.488a2.881,2.881,0,0,1,3.024,1.8c.5,1.368.828,2.232,3.024,2.232a2.584,2.584,0,0,0,2.916-2.448c0-1.944-2.34-6.66-9-6.66-9.792,0-10.944,10.188-10.944,16.272,0,6.876,1.656,9.576,3.024,11.268A9.544,9.544,0,0,0,.36.792c6.66,0,9.828-5.04,9.828-10.44,0-5.076-2.844-9.864-8.964-9.864A6.6,6.6,0,0,0-3.924-17.568Zm3.96,2.952c1.908,0,3.924,1.26,3.924,5.148C3.96-5.652,2.088-4.284,0-4.284c-2.232,0-4-1.62-4-5.148C-4-12.888-2.34-14.616.036-14.616Z"
      transform="translate(10.188 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 7"
    xmlns="http://www.w3.org/2000/svg"
    width="20.736"
    height="30.132"
    viewBox="0 0 20.736 30.132"
    :style="styles"
  >
    <path
      d="M-7.56-29.34a2.423,2.423,0,0,0-2.808,2.556,2.426,2.426,0,0,0,2.808,2.52H2.2c.252,0,.828.036.828.4a1.061,1.061,0,0,1-.18.468,69.09,69.09,0,0,0-3.852,5.9C-4.176-12.06-6.372-3.96-6.372-1.548c0,.612,0,2.34,3.168,2.34C-1.008.792-.4-.252-.288-.684c.072-.288.18-1.584.216-1.872a41.333,41.333,0,0,1,9-20.628c.9-1.116,1.44-1.836,1.44-3.1a2.851,2.851,0,0,0-2.952-3.06Z"
      transform="translate(10.368 29.34)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 8"
    xmlns="http://www.w3.org/2000/svg"
    width="20.376"
    height="30.924"
    viewBox="0 0 20.376 30.924"
    :style="styles"
  >
    <path
      d="M-6.084-16.092c-1.08.54-4.1,2.376-4.1,7.236C-10.188-2.88-5.976.792,0,.792c6.012,0,10.188-3.672,10.188-9.648,0-4.86-3.024-6.7-4.1-7.236a6.454,6.454,0,0,0,3.2-5.9c0-5.472-4.968-8.136-9.288-8.136S-9.288-27.468-9.288-22A6.525,6.525,0,0,0-6.084-16.092ZM0-25.056c2.34,0,3.456,1.476,3.456,3.564A3.264,3.264,0,0,1,0-17.964a3.258,3.258,0,0,1-3.456-3.492C-3.456-23.688-2.232-25.056,0-25.056ZM0-13.212c2.34,0,4.1,1.44,4.1,4.356C4.1-5,1.548-4.284,0-4.284c-1.908,0-4.1-1.08-4.1-4.464C-4.1-12.312-1.8-13.212,0-13.212Z"
      transform="translate(10.188 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 9"
    xmlns="http://www.w3.org/2000/svg"
    width="20.376"
    height="30.924"
    viewBox="0 0 20.376 30.924"
    :style="styles"
  >
    <path
      d="M3.924-11.772C3.6-4.752.936-4.284-.792-4.284a2.806,2.806,0,0,1-2.988-1.8C-4.32-7.452-4.644-8.316-6.8-8.316c-2.376,0-2.952,1.62-2.952,2.448,0,1.944,2.34,6.66,9,6.66C9.036.792,10.188-9.4,10.188-15.48c0-6.876-1.656-9.576-3.024-11.268A9.544,9.544,0,0,0-.36-30.132c-6.66,0-9.828,5.04-9.828,10.44,0,5.076,2.844,9.864,8.964,9.864A6.6,6.6,0,0,0,3.924-11.772ZM0-25.056c2.376,0,4,1.764,4,5.148.036,3.492-1.728,5.184-4,5.184-2.088,0-3.96-1.4-3.96-5.184C-3.96-23.832-1.908-25.056,0-25.056Z"
      transform="translate(10.188 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 10"
    xmlns="http://www.w3.org/2000/svg"
    width="43.704"
    height="30.924"
    viewBox="0 0 43.704 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM12.564-30.132c-1.656,0-5.256.612-7.272,3.456C2.628-22.9,2.376-16.992,2.376-14.688c0,5.436,1.08,15.48,10.188,15.48,9.072,0,10.188-10.044,10.188-15.48C22.752-20.34,21.564-30.132,12.564-30.132Zm0,5.076c4.1,0,4.1,8.028,4.1,10.368,0,2.376,0,10.4-4.1,10.4s-4.1-8.028-4.1-10.4C8.46-17.028,8.46-25.056,12.564-25.056Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 11"
    xmlns="http://www.w3.org/2000/svg"
    width="37.476"
    height="30.924"
    viewBox="0 0 37.476 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34Zm25.128,0V-2.052c0,2.412,1.4,2.844,3.168,2.844,2.592,0,2.916-1.62,2.916-2.844V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 12"
    xmlns="http://www.w3.org/2000/svg"
    width="43.524"
    height="30.924"
    viewBox="0 0 43.524 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM19.764,0a2.411,2.411,0,0,0,2.808-2.556,2.438,2.438,0,0,0-2.808-2.52H11.52c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1C7.524-10.836,2.52-6.948,2.52-3.1A2.986,2.986,0,0,0,5.76,0Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 13"
    xmlns="http://www.w3.org/2000/svg"
    width="43.704"
    height="30.924"
    viewBox="0 0 43.704 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34Zm33.444,3.78a6.593,6.593,0,0,0,3.06-5.868c0-4.356-2.916-8.424-9.4-8.424-7.812,0-9.432,6.192-9.432,8.1s1.332,2.52,2.88,2.52c2.484,0,2.664-1.224,2.808-2.3.432-2.988,2.3-3.24,3.816-3.24,2.268,0,3.312,1.62,3.312,3.564,0,1.62-.5,4-3.348,4-1.332,0-2.952,0-2.952,2.3s1.62,2.3,2.952,2.3c4.212,0,4.212,3.42,4.212,4.428,0,3.2-2.2,4.176-4.212,4.176-3.636,0-4.068-2.628-4.1-2.952-.252-1.692-.468-2.88-3.1-2.88-2.052,0-2.88,1.188-2.88,2.592a8.693,8.693,0,0,0,1.944,5C6.264,0,9.144.792,12.636.792c9.828,0,10.116-7.776,10.116-9.252A8.524,8.524,0,0,0,18.756-15.84Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 14"
    xmlns="http://www.w3.org/2000/svg"
    width="43.92"
    height="30.924"
    viewBox="0 0 43.92 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM13.68-5.94v3.888c0,2.3,1.26,2.844,3.132,2.844,2.628,0,2.916-1.62,2.916-2.844V-5.94c1.368,0,3.24,0,3.24-2.556,0-2.52-1.98-2.52-3.24-2.52V-27.072a3.143,3.143,0,0,0-3.492-3.06A4.589,4.589,0,0,0,12.2-27.72L2.88-12.348a5.313,5.313,0,0,0-.756,2.772A3.4,3.4,0,0,0,5.508-5.94ZM7.6-11.016c-.576,0-.576-.36-.576-.5a1.209,1.209,0,0,1,.18-.5l5.508-9.036a.61.61,0,0,1,.5-.324c.4,0,.468.432.468.576v9.792Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 15"
    xmlns="http://www.w3.org/2000/svg"
    width="43.704"
    height="30.924"
    viewBox="0 0 43.704 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM3.456-14.58c-.216,2.016,1.152,2.592,2.7,2.592A3.368,3.368,0,0,0,8.6-12.96a4.265,4.265,0,0,1,3.672-1.656c3.024,0,4.392,2.124,4.392,5,0,2.052-.576,5.328-4.464,5.328A3.818,3.818,0,0,1,8.136-7.308,2.745,2.745,0,0,0,5.148-9.144c-1.188,0-2.772.5-2.772,2.484C2.376-5,4.644.792,12.42.792c5.616,0,10.332-3.456,10.332-10.728,0-5.94-4-9.756-9.288-9.756A7.522,7.522,0,0,0,8.82-18.4l.468-4.752a1.185,1.185,0,0,1,1.3-1.116h8.244a2.411,2.411,0,0,0,2.808-2.556,2.438,2.438,0,0,0-2.808-2.52H8.856c-2.268,0-3.924.4-4.284,3.852Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 16"
    xmlns="http://www.w3.org/2000/svg"
    width="43.704"
    height="30.924"
    viewBox="0 0 43.704 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM8.64-17.568c.324-7.02,2.988-7.488,4.716-7.488a2.881,2.881,0,0,1,3.024,1.8c.5,1.368.828,2.232,3.024,2.232a2.584,2.584,0,0,0,2.916-2.448c0-1.944-2.34-6.66-9-6.66-9.792,0-10.944,10.188-10.944,16.272,0,6.876,1.656,9.576,3.024,11.268A9.544,9.544,0,0,0,12.924.792c6.66,0,9.828-5.04,9.828-10.44,0-5.076-2.844-9.864-8.964-9.864A6.6,6.6,0,0,0,8.64-17.568Zm3.96,2.952c1.908,0,3.924,1.26,3.924,5.148,0,3.816-1.872,5.184-3.96,5.184-2.232,0-4-1.62-4-5.148C8.568-12.888,10.224-14.616,12.6-14.616Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 17"
    xmlns="http://www.w3.org/2000/svg"
    width="43.884"
    height="30.924"
    viewBox="0 0 43.884 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM5-29.34A2.423,2.423,0,0,0,2.2-26.784,2.426,2.426,0,0,0,5-24.264H14.76c.252,0,.828.036.828.4a1.061,1.061,0,0,1-.18.468,69.09,69.09,0,0,0-3.852,5.9C8.388-12.06,6.192-3.96,6.192-1.548c0,.612,0,2.34,3.168,2.34,2.2,0,2.808-1.044,2.916-1.476.072-.288.18-1.584.216-1.872a41.333,41.333,0,0,1,9-20.628c.9-1.116,1.44-1.836,1.44-3.1a2.851,2.851,0,0,0-2.952-3.06Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 18"
    xmlns="http://www.w3.org/2000/svg"
    width="43.704"
    height="30.924"
    viewBox="0 0 43.704 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34ZM6.48-16.092c-1.08.54-4.1,2.376-4.1,7.236C2.376-2.88,6.588.792,12.564.792c6.012,0,10.188-3.672,10.188-9.648,0-4.86-3.024-6.7-4.1-7.236a6.454,6.454,0,0,0,3.2-5.9c0-5.472-4.968-8.136-9.288-8.136S3.276-27.468,3.276-22A6.525,6.525,0,0,0,6.48-16.092Zm6.084-8.964c2.34,0,3.456,1.476,3.456,3.564a3.264,3.264,0,0,1-3.456,3.528,3.258,3.258,0,0,1-3.456-3.492C9.108-23.688,10.332-25.056,12.564-25.056Zm0,11.844c2.34,0,4.1,1.44,4.1,4.356,0,3.852-2.556,4.572-4.1,4.572-1.908,0-4.1-1.08-4.1-4.464C8.46-12.312,10.764-13.212,12.564-13.212Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 19"
    xmlns="http://www.w3.org/2000/svg"
    width="43.704"
    height="30.924"
    viewBox="0 0 43.704 30.924"
    :style="styles"
  >
    <path
      d="M-14.688-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844C-8.928.792-8.6-.828-8.6-2.052V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34Zm31.176,7.848c-.324,7.02-2.988,7.488-4.716,7.488a2.806,2.806,0,0,1-2.988-1.8C8.244-7.452,7.92-8.316,5.76-8.316c-2.376,0-2.952,1.62-2.952,2.448,0,1.944,2.34,6.66,9,6.66C21.6.792,22.752-9.4,22.752-15.48c0-6.876-1.656-9.576-3.024-11.268A9.544,9.544,0,0,0,12.2-30.132c-6.66,0-9.828,5.04-9.828,10.44,0,5.076,2.844,9.864,8.964,9.864A6.6,6.6,0,0,0,16.488-11.772ZM12.564-25.056c2.376,0,4,1.764,4,5.148.036,3.492-1.728,5.184-4,5.184-2.088,0-3.96-1.4-3.96-5.184C8.6-23.832,10.656-25.056,12.564-25.056Z"
      transform="translate(20.952 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 20"
    xmlns="http://www.w3.org/2000/svg"
    width="45.36"
    height="30.924"
    viewBox="0 0 45.36 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0ZM12.564-30.132c-1.656,0-5.256.612-7.272,3.456C2.628-22.9,2.376-16.992,2.376-14.688c0,5.436,1.08,15.48,10.188,15.48,9.072,0,10.188-10.044,10.188-15.48C22.752-20.34,21.564-30.132,12.564-30.132Zm0,5.076c4.1,0,4.1,8.028,4.1,10.368,0,2.376,0,10.4-4.1,10.4s-4.1-8.028-4.1-10.4C8.46-17.028,8.46-25.056,12.564-25.056Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 21"
    xmlns="http://www.w3.org/2000/svg"
    width="39.132"
    height="30.924"
    viewBox="0 0 39.132 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0Zm15.8-19.62V-2.052c0,2.412,1.4,2.844,3.168,2.844,2.592,0,2.916-1.62,2.916-2.844V-28.224c0-.828-.792-1.908-2.52-1.908-1.836,0-2.16.9-2.628,2.124a5.465,5.465,0,0,1-4.464,3.744c-1.512.252-2.736.432-2.736,2.3a2.114,2.114,0,0,0,2.088,2.34Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 22"
    xmlns="http://www.w3.org/2000/svg"
    width="45.18"
    height="30.132"
    viewBox="0 0 45.18 30.132"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0ZM19.764,0a2.411,2.411,0,0,0,2.808-2.556,2.438,2.438,0,0,0-2.808-2.52H11.52c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1C7.524-10.836,2.52-6.948,2.52-3.1A2.986,2.986,0,0,0,5.76,0Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 23"
    xmlns="http://www.w3.org/2000/svg"
    width="45.36"
    height="30.924"
    viewBox="0 0 45.36 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0Zm24.12-15.84a6.593,6.593,0,0,0,3.06-5.868c0-4.356-2.916-8.424-9.4-8.424-7.812,0-9.432,6.192-9.432,8.1s1.332,2.52,2.88,2.52c2.484,0,2.664-1.224,2.808-2.3.432-2.988,2.3-3.24,3.816-3.24,2.268,0,3.312,1.62,3.312,3.564,0,1.62-.5,4-3.348,4-1.332,0-2.952,0-2.952,2.3s1.62,2.3,2.952,2.3c4.212,0,4.212,3.42,4.212,4.428,0,3.2-2.2,4.176-4.212,4.176-3.636,0-4.068-2.628-4.1-2.952-.252-1.692-.468-2.88-3.1-2.88-2.052,0-2.88,1.188-2.88,2.592a8.693,8.693,0,0,0,1.944,5C6.264,0,9.144.792,12.636.792c9.828,0,10.116-7.776,10.116-9.252A8.524,8.524,0,0,0,18.756-15.84Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 24"
    xmlns="http://www.w3.org/2000/svg"
    width="45.576"
    height="30.924"
    viewBox="0 0 45.576 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0ZM13.68-5.94v3.888c0,2.3,1.26,2.844,3.132,2.844,2.628,0,2.916-1.62,2.916-2.844V-5.94c1.368,0,3.24,0,3.24-2.556,0-2.52-1.98-2.52-3.24-2.52V-27.072a3.143,3.143,0,0,0-3.492-3.06A4.589,4.589,0,0,0,12.2-27.72L2.88-12.348a5.313,5.313,0,0,0-.756,2.772A3.4,3.4,0,0,0,5.508-5.94ZM7.6-11.016c-.576,0-.576-.36-.576-.5a1.209,1.209,0,0,1,.18-.5l5.508-9.036a.61.61,0,0,1,.5-.324c.4,0,.468.432.468.576v9.792Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 25"
    xmlns="http://www.w3.org/2000/svg"
    width="45.36"
    height="30.924"
    viewBox="0 0 45.36 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0Zm8.82-14.58c-.216,2.016,1.152,2.592,2.7,2.592A3.368,3.368,0,0,0,8.6-12.96a4.265,4.265,0,0,1,3.672-1.656c3.024,0,4.392,2.124,4.392,5,0,2.052-.576,5.328-4.464,5.328A3.818,3.818,0,0,1,8.136-7.308,2.745,2.745,0,0,0,5.148-9.144c-1.188,0-2.772.5-2.772,2.484C2.376-5,4.644.792,12.42.792c5.616,0,10.332-3.456,10.332-10.728,0-5.94-4-9.756-9.288-9.756A7.522,7.522,0,0,0,8.82-18.4l.468-4.752a1.185,1.185,0,0,1,1.3-1.116h8.244a2.411,2.411,0,0,0,2.808-2.556,2.438,2.438,0,0,0-2.808-2.52H8.856c-2.268,0-3.924.4-4.284,3.852Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 26"
    xmlns="http://www.w3.org/2000/svg"
    width="45.36"
    height="30.924"
    viewBox="0 0 45.36 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0Zm14-17.568c.324-7.02,2.988-7.488,4.716-7.488a2.881,2.881,0,0,1,3.024,1.8c.5,1.368.828,2.232,3.024,2.232a2.584,2.584,0,0,0,2.916-2.448c0-1.944-2.34-6.66-9-6.66-9.792,0-10.944,10.188-10.944,16.272,0,6.876,1.656,9.576,3.024,11.268A9.544,9.544,0,0,0,12.924.792c6.66,0,9.828-5.04,9.828-10.44,0-5.076-2.844-9.864-8.964-9.864A6.6,6.6,0,0,0,8.64-17.568Zm3.96,2.952c1.908,0,3.924,1.26,3.924,5.148,0,3.816-1.872,5.184-3.96,5.184-2.232,0-4-1.62-4-5.148C8.568-12.888,10.224-14.616,12.6-14.616Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 27"
    xmlns="http://www.w3.org/2000/svg"
    width="45.54"
    height="30.924"
    viewBox="0 0 45.54 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0ZM5-29.34A2.423,2.423,0,0,0,2.2-26.784,2.426,2.426,0,0,0,5-24.264H14.76c.252,0,.828.036.828.4a1.061,1.061,0,0,1-.18.468,69.09,69.09,0,0,0-3.852,5.9C8.388-12.06,6.192-3.96,6.192-1.548c0,.612,0,2.34,3.168,2.34,2.2,0,2.808-1.044,2.916-1.476.072-.288.18-1.584.216-1.872a41.333,41.333,0,0,1,9-20.628c.9-1.116,1.44-1.836,1.44-3.1a2.851,2.851,0,0,0-2.952-3.06Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 28"
    xmlns="http://www.w3.org/2000/svg"
    width="45.36"
    height="30.924"
    viewBox="0 0 45.36 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0ZM6.48-16.092c-1.08.54-4.1,2.376-4.1,7.236C2.376-2.88,6.588.792,12.564.792c6.012,0,10.188-3.672,10.188-9.648,0-4.86-3.024-6.7-4.1-7.236a6.454,6.454,0,0,0,3.2-5.9c0-5.472-4.968-8.136-9.288-8.136S3.276-27.468,3.276-22A6.525,6.525,0,0,0,6.48-16.092Zm6.084-8.964c2.34,0,3.456,1.476,3.456,3.564a3.264,3.264,0,0,1-3.456,3.528,3.258,3.258,0,0,1-3.456-3.492C9.108-23.688,10.332-25.056,12.564-25.056Zm0,11.844c2.34,0,4.1,1.44,4.1,4.356,0,3.852-2.556,4.572-4.1,4.572-1.908,0-4.1-1.08-4.1-4.464C8.46-12.312,10.764-13.212,12.564-13.212Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 29"
    xmlns="http://www.w3.org/2000/svg"
    width="45.36"
    height="30.924"
    viewBox="0 0 45.36 30.924"
    :style="styles"
  >
    <path
      d="M-5.364,0A2.411,2.411,0,0,0-2.556-2.556a2.438,2.438,0,0,0-2.808-2.52h-8.244c-.252,0-.864-.036-.864-.468,0-.936,3.564-3.492,4.068-3.852,4.824-3.456,7.776-5.58,7.776-10.944,0-5.832-3.924-9.792-9.9-9.792-7.956,0-9.828,6.588-9.828,9.036a2.812,2.812,0,0,0,3.1,2.844c2.628,0,2.808-1.656,2.88-2.7.072-.72.324-4.1,3.924-4.1,2.412,0,3.78,1.692,3.78,4.356,0,3.384-1.584,4.536-6.48,8.1-2.448,1.764-7.452,5.652-7.452,9.5A2.986,2.986,0,0,0-19.368,0ZM16.488-11.772c-.324,7.02-2.988,7.488-4.716,7.488a2.806,2.806,0,0,1-2.988-1.8C8.244-7.452,7.92-8.316,5.76-8.316c-2.376,0-2.952,1.62-2.952,2.448,0,1.944,2.34,6.66,9,6.66C21.6.792,22.752-9.4,22.752-15.48c0-6.876-1.656-9.576-3.024-11.268A9.544,9.544,0,0,0,12.2-30.132c-6.66,0-9.828,5.04-9.828,10.44,0,5.076,2.844,9.864,8.964,9.864A6.6,6.6,0,0,0,16.488-11.772ZM12.564-25.056c2.376,0,4,1.764,4,5.148.036,3.492-1.728,5.184-4,5.184-2.088,0-3.96-1.4-3.96-5.184C8.6-23.832,10.656-25.056,12.564-25.056Z"
      transform="translate(22.608 30.132)"
      :fill="fill"
    />
  </svg>
  <svg
    v-else-if="num === 30"
    xmlns="http://www.w3.org/2000/svg"
    width="45.504"
    height="30.924"
    viewBox="0 0 45.504 30.924"
    :style="styles"
  >
    <path
      d="M-6.372-15.84a6.593,6.593,0,0,0,3.06-5.868c0-4.356-2.916-8.424-9.4-8.424-7.812,0-9.432,6.192-9.432,8.1s1.332,2.52,2.88,2.52c2.484,0,2.664-1.224,2.808-2.3.432-2.988,2.3-3.24,3.816-3.24,2.268,0,3.312,1.62,3.312,3.564,0,1.62-.5,4-3.348,4-1.332,0-2.952,0-2.952,2.3s1.62,2.3,2.952,2.3c4.212,0,4.212,3.42,4.212,4.428,0,3.2-2.2,4.176-4.212,4.176-3.636,0-4.068-2.628-4.1-2.952-.252-1.692-.468-2.88-3.1-2.88-2.052,0-2.88,1.188-2.88,2.592a8.693,8.693,0,0,0,1.944,5C-18.864,0-15.984.792-12.492.792-2.664.792-2.376-6.984-2.376-8.46A8.524,8.524,0,0,0-6.372-15.84ZM12.564-30.132c-1.656,0-5.256.612-7.272,3.456C2.628-22.9,2.376-16.992,2.376-14.688c0,5.436,1.08,15.48,10.188,15.48,9.072,0,10.188-10.044,10.188-15.48C22.752-20.34,21.564-30.132,12.564-30.132Zm0,5.076c4.1,0,4.1,8.028,4.1,10.368,0,2.376,0,10.4-4.1,10.4s-4.1-8.028-4.1-10.4C8.46-17.028,8.46-25.056,12.564-25.056Z"
      transform="translate(22.752 30.132)"
      :fill="fill"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

interface Styles {
  [key: string]: string
}

export default defineComponent({
  props: {
    num: {
      type: Number,
      required: true,
    },
    fill: {
      type: String,
      default: '#333',
    },
    styles: {
      type: Object as PropType<Styles>,
    },
  },
})
</script>

<style lang="scss" scoped></style>
