<template>
  <div class="auth" v-if="isShow" :class="{ isVibrate: state.isError }">
    <div class="auth_top">
      <div class="auth_inner">
        <h1 class="auth_title">
          <img :src="`img/auth/${lang}/title.svg`" :alt="alt.title[lang]" />
        </h1>
        <div class="keyBtn_wrap">
          <div @click="resetKey(0)" class="keyBtn keyBtn--blue">
            <img
              v-if="key.selected[0]"
              :src="`img/auth/item_0${key.selected[0]}.png`"
              alt=""
            />
            <img v-else src="img/auth/num_01.svg" alt="1" />
          </div>
          <div @click="resetKey(1)" class="keyBtn keyBtn--pink">
            <img
              v-if="key.selected[1]"
              :src="`img/auth/item_0${key.selected[1]}.png`"
              alt=""
            />
            <img v-else src="img/auth/num_02.svg" alt="2" />
          </div>
          <div class="keyBtn keyBtn--orange">
            <img
              v-if="key.selected[2]"
              :src="`img/auth/item_0${key.selected[2]}.png`"
              alt=""
            />
            <img v-else src="img/auth/num_03.svg" alt="3" />
          </div>

          <div class="keyBtn_error" v-if="state.isError">
            <img :src="`img/auth/${lang}/error.svg`" :alt="alt.error[lang]" />
          </div>
        </div>
      </div>
    </div>

    <div class="auth_bottom">
      <div class="keyBtn_wrap">
        <template v-for="n in 6" :key="n">
          <button
            class="keyBtn keyBtn--shadow"
            type="button"
            :value="n"
            :disabled="key.selected.includes(n)"
            @click="selectKey(n)"
          >
            <img :src="`img/auth/item_0${n}.png`" alt="" />
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

interface Key {
  current: number
  selected: number[]
}

interface State {
  isError: boolean
}

export default defineComponent({
  name: 'Auth',

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
  },

  setup(props, { emit }) {
    if (props.isShow) gtag('event', 'key')

    const store = useStore()

    const key = reactive<Key>({
      current: 0,
      selected: [0, 0, 0],
    })
    const state = reactive<State>({
      isError: false,
    })

    const alt = {
      title: {
        ja: '今日のカギ ※カギは毎日変わるよ',
        en: 'Todays Key *The key changes every day!',
        zh: '今日秘钥 ※秘钥每天都不一样哦',
        ko: '오늘의 열쇠 ※열쇠는 매일 바뀐답니다',
      },
      error: {
        ja: 'もういちどやりなおしてね',
        en: '',
        zh: '',
        ko: '',
      },
    }

    const authenticateKey = () => {
      const selectedKey = key.selected.join('')
      state.isError = false
      store.commit('startLoading')
      store
        .dispatch('authenticateKey', selectedKey)
        .then((result: boolean) => {
          if (result) {
            Cookies.set(app_cookie.todays_key, selectedKey, {
              expires: 1 / 2,
            })
            emit('success')
          } else {
            const useAgent = navigator.userAgent.toLowerCase()
            if (useAgent.indexOf('android') > -1) {
              window.navigator.vibrate([100, 100, 100, 100, 100])
            }
            state.isError = true

            key.current = 0
            key.selected = [0, 0, 0]

            gtag('event', 'key_fault')
          }
          store.commit('endLoading')
        })
        .catch(() => {
          key.current = 0
          key.selected = [0, 0, 0]
          store.dispatch('showErrorModal', 'auth')
          store.commit('endLoading')
        })
    }

    const selectKey = (n: number) => {
      key.selected[key.current] = n
      key.current++

      if (key.current >= 3) {
        authenticateKey()
      }
    }

    const resetKey = (index: number) => {
      if (key.selected[0] === 0 && index === 1) {
        return
      }
      key.current = index

      if (index === 0) {
        key.selected = [0, 0, 0]
      } else if (index === 1) {
        key.selected[1] = 0
        key.selected[2] = 0
      }
    }

    return {
      key,
      state,
      authenticateKey,
      selectKey,
      resetKey,
      alt,
    }
  },
})
</script>

<style scoped lang="scss">
@keyframes move {
  0% {
    transform: translate(0px, 0px) rotateZ(0deg);
  }
  25% {
    transform: translate(2px, 2px) rotateZ(1deg);
  }
  50% {
    transform: translate(0px, 2px) rotateZ(0deg);
  }
  75% {
    transform: translate(2px, 0px) rotateZ(-1deg);
  }
  100% {
    transform: translate(0px, 0px) rotateZ(0deg);
  }
}

.auth {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var.$color-primary-bg;
  background-image: url('/img/common/dot_blue.svg');
  &.isVibrate {
    animation-name: move;
    animation-duration: 0.1s;
    animation-iteration-count: 5;
    animation-delay: 0.1s;
  }

  &_top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 35px;
    background-color: #fff;
    mask-image: url('/img/common/wave_section.png');
    mask-repeat: repeat-x;
    mask-size: auto 100%;
    height: 52%;
    flex-shrink: 1;
    flex-grow: 1;
  }

  &_inner {
    width: 100%;
  }

  &_title {
    text-align: center;
    margin-bottom: 32px;
  }

  &_bottom {
    position: relative;
    overflow: hidden;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48%;
    max-height: 270px;
    flex-shrink: 1;
    flex-grow: 0;

    .-os-android & {
      max-height: 310px;
      padding-bottom: 40px;
    }
  }
}

.keyBtn_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 305px;
  justify-content: space-between;
  .keyBtn {
    &:nth-child(n + 4) {
      margin-top: 20px;
    }
  }
}

.keyBtn {
  width: calc((100% - 35px) / 3);
  border-radius: 20px;
  background-color: rgba(#dbdbdb, 0.5);
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 1;
    border: 4px solid transparent;
    border-radius: 20px;
  }
  img {
    width: 100%;
  }
}
.keyBtn--shadow {
  box-shadow: 0 3px 6px rgba(#00445c, 0.2);
  &:disabled {
    box-shadow: none;
    img {
      display: none;
    }
  }
}
.keyBtn--blue {
  &::before {
    border-color: var.$color-primary;
  }
}
.keyBtn--pink {
  &::before {
    border-color: var.$color-secondary;
  }
}
.keyBtn--orange {
  &::before {
    border-color: var.$color-orange;
  }
}
.keyBtn_error {
  line-height: 1;
  position: absolute;
  // width: 162px;
  bottom: -34px;
  left: 50%;
  transform: translateX(-50%);
  // img {
  //   width: 161px;
  //   min-height: 12px;
  // }
}
</style>
