
import { defineComponent, PropType } from 'vue'
import Button from '@/components/elements/Button.vue'

export default defineComponent({
  name: 'QuizAnswerButton',

  components: {
    Button,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    answer: {
      validator: (value: string) => {
        return ['a', 'b'].includes(value)
      },
      required: true,
    },
    answerQuiz: {
      type: Function as PropType<(option: 'a' | 'b') => void>,
      required: true,
    },
  },
})
