<template>
  <div class="playerBg" :class="{ '-show': mode === 'full' }"></div>
  <div
    class="player"
    :class="{
      '-full': mode === 'full',
    }"
    :style="{ transform: `translateY(${touchState.move}px)` }"
  >
    <button
      type="button"
      class="playerMiniControlBtn_close"
      @click="
        () => {
          close()
          guideMeta.closeDescription()
        }
      "
    >
      <img src="/img/common/close_white.svg" :alt="alt[lang].close" />
    </button>

    <div v-show="mode === 'full'">
      <div class="playerCont">
        <div class="playerCont_number">
          <img src="/img/usage/icon-usage.png" width="65" height="60" alt="" />
        </div>

        <AudioMeta
          ref="guideMeta"
          :lang="lang"
          :audioName="guideName || ''"
          :audioDescription="guideDescription || ''"
          :gtagTrackOpenText="gtagTrackOpenText"
          :usageNotice="true"
        />

        <AudioController
          :lang="lang"
          :totalTime="totalTime"
          :currentTime="currentTime"
          :setCurrentTime="setCurrentTime"
          :isPlaying="isPlaying"
          :disableMovePrev="true"
          :disableMoveNext="true"
          :play="play"
          :pause="pause"
          :moveGuide="
            (direction) => {
              guideMeta.closeDescription()
              moveGuide(direction)
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
import { defineComponent, PropType, ref, reactive } from 'vue'
import AudioController from '@/components/block/AudioController.vue'
import AudioMeta from '@/components/block/AudioMeta.vue'

export default defineComponent({
  name: 'SimplePlayer',

  components: {
    AudioController,
    AudioMeta,
  },

  props: {
    playerID: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
    mode: {
      type: String,
      required: true,
    },
    guideName: {
      type: String,
      required: true,
    },
    guideDescription: {
      type: String,
      required: true,
    },
    totalTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    play: {
      type: Function as PropType<() => void>,
      required: true,
    },
    pause: {
      type: Function as PropType<() => void>,
      required: true,
    },
    moveGuide: {
      type: Function as PropType<(direction: string) => void>,
      required: true,
    },
    setCurrentTime: {
      type: Function as PropType<(time: number) => void>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  setup(props) {
    const guideMeta = ref()

    const touchState = reactive({
      start: 0,
      move: 0,
    })

    const gtagTrackOpenText = (): void => {
      gtag('event', `player_text_${props.playerID}`)
    }

    const alt = {
      ja: {
        down: '小さくする',
        play: '再生',
        stop: '停止',
        close: '閉じる',
      },
      en: {
        down: 'Make it smaller',
        play: 'play',
        stop: 'stop',
        close: 'close',
      },
      zh: {
        down: '让它更小',
        play: '再生产',
        stop: '停止',
        close: '特写',
      },
      ko: {
        down: '작게',
        play: '재생',
        stop: '정지',
        close: '닫기',
      },
    }

    return {
      guideMeta,
      touchState,
      gtagTrackOpenText,
      alt,
    }
  },
})
</script>

<style scoped lang="scss">
.playerBg {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.6);
  display: none;

  &.-show {
    display: block;
  }
}

.player {
  position: fixed;
  z-index: 10;
  bottom: -100vh;
  left: 0;
  width: 100vw;
  height: 492px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  z-index: 100;

  &.-full {
    bottom: 0;

    .-os-android & {
      bottom: 40px;
    }
  }
}

.playerCont {
  height: 492px;
  padding: 50px 35px 30px;
  overflow-y: auto;
  position: relative;
  z-index: 0;

  &_number {
    width: 120px;
    height: 120px;
    background: #36afdf;
    margin-bottom: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.playerMiniControlBtn {
  &_close {
    padding: 4px;
    width: auto;
    position: absolute;
    top: -50px;
    right: 15px;
    cursor: pointer;
  }
}
</style>
